<template>
  <v-container class="mb-10">
    <v-row>
      <v-col>
        <v-select
          v-if="gameSelection"
          v-model="selectedGame"
          :items="gameSelection"
          label="Select Game"
          item-text="name"
          item-value="id"
          hide-details
          @change="selectGame"
          :loading="loading"
          :disabled="loading"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="hasList">
      <div class v-for="(resultsList, index) in listsByDate" :key="index">
        <v-subheader
          class="text-uppercase font-weight-medium px-0"
        >{{ index }} {{ getDay(resultsList) }}</v-subheader>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-for="(result, index) in resultsList"
            :key="index"
          >
            <combination-tile
              class="results-comb"
              :tickets="getTicketsCount(result) + ' Tickets'"
              :winAmount="result.client.jackpot_amount"
              :winner="result.winners"
              :title="result.label"
              :clientName="result.client.name"
              :drawDate="result.draws_at"
              :numbers="result.combination ? result.combination.split(',') : []"
              :slug="result.client.slug"
              :boards="result.boards"
              :ticketsWinCount="getTicketsWinCount(result)"
              @set-current-result="setCurrentResult"
            />
          </v-col>
        </v-row>
      </div>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card class="mb-8 ticket-dialog">
          <v-toolbar dark flat color="orange accent-3" style="border-radius:0;">
            <v-toolbar-title>Tickets</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="pt-4 pb-10" style="background: #e5e3e9; ">
            <tickets
              class="results-comb"
              v-for="(ticket, index) in myTickets"
              :title="currentResult.clientName + ' ' + currentResult.label"
              :slug="currentResult.slug"
              :key="index"
              :status="ticket.win"
              :ticketref="ticket.ticket"
              :submitAt="'Submitted :' + ticket.created_at"
              :isRambol="ticket.is_rambol"
              :betamount="'&#8369;' + formatMoney(ticket.amount)"
              :numbers="ticket.combination.split(',')"
              :roll="ticket.roll"
            />
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="lists < 1" class="text-center text-lose pb-8">
      <div class="" v-if="loading">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
      </div>
      <div v-else>
        <p>No results available</p>
      </div>
    </div>
    <v-row>
      <v-col class="d-flex align-center justify-center">
        <div v-if="hasMore" class="text-center mb-8">
          <v-btn text small :loading="loading" @click.stop.prevent="loadMore()"
            >Show More</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import list from "./../mixins/list";
import helpers from "./../mixins/helpers";
import _ from "lodash";
import CombinationTile from "./../components/CombinationTile";
import Tickets from "./../components/Tickets";

export default {
  mixins: [list, helpers],
  components: {
    CombinationTile,
    Tickets,
  },
  computed: {
    gameSelection() {
      return this.$store.getters.gameSelection;
    },
    listsByDate() {
      return _.groupBy(this.lists, "date");
    },
  },
  data: () => ({
    dataUrl: "/lotto/draws",
    selectedGame: 1,
    dialog: false,
    myTickets: [],
    currentResult: {},
  }),
  methods: {
    getDay(resultsList) {
      return _.head(resultsList).day;
    },

    getTicketsWinCount(result) {
      return _.filter(result.boards, (board) => board.win).length;
    },
    setCurrentResult(resultData) {
      this.currentResult = resultData;
      this.myTickets = this.currentResult.boards;

      if (this.myTickets.length > 0) {
        this.dialog = true;
      }
    },
    getTicketsCount(result) {
      let mytickets = Object.keys(result.boards).length;
      return mytickets;
    },

    selectGame() {
      if (this.selectedGame) {
        this.selectedGame === 1
          ? (this.params = "")
          : (this.params = "&client_id=" + this.selectedGame);
        this.getList();
      }
    },
  },
};
</script>