import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        knownRoutes: [
            'landing',
            'play',
            'account',
            'account-tickets',
            'transactions',
            'results',
            'manual-pick',
            'game',
            'game-play',
            'advance',
            'wallet',
            'add-credits',
            'withdraw',
            'cash-ins',
            'transactions',
            'help',
            'settings',
            'success'
        ],
        exemptRoutes: [
            'register',
            'registered',
            'login',
            'forgot-password',
            'new-password',
            'verify'
        ],
        transactions: [
            {
                id: 12,
                ammount: 2000.00,
                desc: 'add credit',
                is_credit: true
            },
            {
                id: 12,
                ammount: 4000.00,
                desc: 'cash out',
                is_credit: false
            },
            {
                id: 12,
                ammount: 3000.00,
                desc: '3D Lotto Winner',
                is_credit: true
            },

        ],
        user: {
            id: null,
            name: null,
            mobile: null,
            mobile_verified: null,
            credits: 0,
            is_stream_blocked: false,
            is_blocked: false
        },
        video: {
            lock_message: null,
            video_url: null
        },
        games: [],
        history: null,
        wallet: {
            deposits: null,
            withdrawals: null,
            transactions: null
        },
        walletModal: {
            show: false
        },
        outlets: {
            deposits: null,
            withdrawals: null
        },
        snackbar: {
            show: false,
            message: null,
            timeout: 5000,
            color: 'green darken-2'
        },
        overlay: {
            show: false,
            button: true,
            message: null,
            type: null
        },
        win: {
            show: false,
            message: null,
            timeout: 10000
        },
        prompt: {
            show: false,
            message: null,
            type: null
        },
        appEvent: null,
        loading: false,
        responsive: false
    },
    getters: {
        games(state) {
            let filtered = state.games.filter(game => {
                if (game.slug !== 'lotto') return game
            })
            return filtered
        },
        gameSelection(state) {
            let selections = state.games.map(game => {
                if (game.slug === 'lotto') game.name = 'All Games'
                return game
            })
            return selections
        }
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        },
        SET_VIDEO(state, payload) {
            state.video = payload
        },
        SET_GAMES(state, payload) {
            state.games = payload
        },
        SET_HISTORY(state, payload) {
            if (state.history && state.history.data instanceof Array) {
                if (payload.new_data) {
                    state.history.data = payload.data
                    state.history.meta = payload.meta
                } else {
                    state.history.data = [...new Set([].concat(...state.history.data, ...payload.data))]
                    state.history.meta.last_page = payload.meta.last_page
                    if (payload.meta.current_page > 1) state.history.meta.current_page = payload.meta.current_page
                }
            } else {
                state.history = payload
            }
        },
        SET_OUTLETS(state, payload) {
            state.outlets[payload.mode] = payload
        },
        SET_WALLET(state, payload) {
            state.wallet[payload.mode] = payload
        },
        SET_WALLET_MODAL(state, payload) {
            state.walletModal.show = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_RESPONSIVE(state, payload) {
            state.responsive = payload
        },
        SET_SNACKBAR(state, payload) {
            if (payload.message) {
                state.snackbar.message = payload.message
                state.snackbar.show = true
            }

            payload.color ? state.snackbar.color = payload.color : state.snackbar.color = 'success'
        },
        SET_SNACKBAR_HIDE(state) {
            state.snackbar.show = false
        },
        SET_OVERLAY(state, payload) {
            if (payload.message) {
                state.overlay.message = payload.message
                state.overlay.button = payload.button
                if (payload.type) state.overlay.type = payload.type
                state.overlay.show = true
            }
        },
        SET_OVERLAY_HIDE(state) {
            state.overlay.show = false
        },
        SET_WIN(state, payload) {
            if (payload.message) {
                state.win.message = payload.message
                state.win.show = true
            }
        },
        SET_WIN_HIDE(state) {
            state.win.show = false
        },
        SET_PROMPT(state, payload) {
            if (payload.message) {
                state.prompt.message = payload.message
                state.prompt.show = true
            }
        },
        SET_PROMPT_HIDE(state) {
            state.prompt.show = false
        },
        SET_APP_EVENT(state, payload) {
            state.appEvent = payload
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('SET_USER', payload)
        },
        setVideo({ commit }, payload) {
            commit('SET_VIDEO', payload)
        },
        setHistory({ commit }, payload) {
            commit('SET_HISTORY', payload)
        },
        setGames({ commit }, payload) {
            commit('SET_GAMES', payload)
        },
        getHistory() {
            console.log('get history')
        },
        setOutlets({ commit }, payload) {
            commit('SET_OUTLETS', payload)
        },
        setWallet({ commit }, payload) {
            commit('SET_WALLET', payload)
        },
        showWalletModal({ commit }) {
            commit('SET_WALLET_MODAL', true)
        },
        hideWalletModal({ commit }) {
            commit('SET_WALLET_MODAL', false)
        },
        loading({ commit }, payload) {
            commit('SET_LOADING', payload)
        },
        responsive({ commit }, payload) {
            commit('SET_RESPONSIVE', payload)
        },
        snackbar({ commit }, payload) {
            commit('SET_SNACKBAR', payload)
        },
        snackbarHide({ commit }) {
            commit('SET_SNACKBAR_HIDE')
        },
        overlay({ commit }, payload) {
            commit('SET_OVERLAY', payload)
        },
        overlayHide({ commit }) {
            commit('SET_OVERLAY_HIDE')
        },
        win({ commit }, payload) {
            commit('SET_WIN', payload)
        },
        winHide({ commit }) {
            commit('SET_WIN_HIDE')
        },
        prompt({ commit }, payload) {
            commit('SET_PROMPT', payload)
        },
        promptHide({ commit }) {
            commit('SET_PROMPT_HIDE')
        },
        appEvent({ commit }, payload) {
            commit('SET_APP_EVENT', payload)
        }
    }
})
