<template>
  <v-container dark class="fill-height" fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="main-form" @submit="submit()">
      <div class="text-center mb-4">
        <img src="/imgs/logo.png" class="reg-logo mb-0" />
        <h4>Registration</h4>
      </div>
      <v-text-field
        dark
        v-model="credentials.mobile"
        label="Mobile Number"
        type="number"
        :loading="busy"
        :rules="[rules.required, rules.phone]"
        :error-messages="error && error.mobile ? error.mobile[0] : ''"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.name"
        label="Name"
        :loading="busy"
        :rules="[rules.required]"
        :error-messages="error && error.name ? error.name[0] : ''"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.password"
        label="Password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        :loading="busy"
        :rules="[rules.required, rules.password]"
        :error-messages="error && error.password ? error.password[0] : ''"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.password_confirmation"
        label="Confirm Password"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2"
        :loading="busy"
        :rules="[rules.required, rules.password, passwordConfirmationRule]"
        :error-messages="error && error.password_confirmation ? error.password_confirmation[0] : ''"
        @keypress.enter="submit()"
        required
      ></v-text-field>
        <div class="mt-2 mb-5">
            <v-checkbox
                dark
                class="mt-0 mb-2"
                v-model="credentials.terms"
                required
                hide-details
                :rules="[v => !!v || '']">
                <template v-slot:label>
                <a href="#"
                    class="white--text"
                    @click.stop.prevent="terms = true; credentials.terms = true">
                    &nbsp;Terms and Conditions
                </a>
                </template>
            </v-checkbox>
        </div>
      <div class="text-center mt-5 pt-3">
        <v-btn color="#fcd016" rounded class="mb-0 elevation-0" @click="submit()" :loading="busy" block>Register</v-btn>
        <br />
        <v-btn dark text rounded class="mb-0" @click.stop="$router.push({ name: 'login' })">Login</v-btn>
        <div v-if="this.credentials.ref" color="grey--text"><small>{{ this.credentials.ref }}</small></div>
      </div>
    </v-form>
    <v-dialog light v-model="terms" max-width="600">
      <v-card class="terms-modal">
        <div class="win-content px-5 py-4 text-justify">
          <terms />
        </div>
        <v-card-actions>
            <v-btn block color="primary" light @click.stop.prevent="terms = false">I AGREE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    show1: false,
    show2: false,
    terms: false,
    credentials: {
      mobile: null,
      name: null,
      password: null,
      password_confirmation: null,
      guid: null,
      ref: null,
      terms: false
    }
  }),
  components: {
    Terms: () => import('./../modals/Terms.vue')
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.credentials.password === this.credentials.password_confirmation ||
        "Password must match"
      );
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) this.register(this.credentials);
    }
  },
  mounted() {
    if (this.urlParams) {
      this.credentials.guid = this.urlParams.get("guid") || null;
      this.credentials.ref = this.urlParams.get("ref") || null;
    }
  }
};
</script>