<template>
    <v-overlay :value="win.show" opacity=".95" color="#273b67" z-index="2000" class="text-center" close-delay="5000">
        <div class="win-content text-center">
          <div class="win-result mb-5" v-if="win.message">
            <div v-if="win.message.draw && win.message.draw.client">
              <img :src='"/imgs/" + win.message.draw.client.slug + ".png"' width="60" class="mb-1" />'
            </div>
            <h2 class="mb-0 yellow--text">Congratulations!</h2>
            You Won <strong>&#8369; {{ formatMoney(win.message.win_amount) }}</strong>
          </div>
          <v-btn color="warning" @click.stop="win.show = false">OK</v-btn>
        </div>
    </v-overlay>
</template>

<script>
import helpers from "./../mixins/helpers.js";

export default {
  mixins: [helpers],
  computed: {
    win: {
      get() {
        return this.$store.state.win;
      },
      set(val) {
        val || this.$store.dispatch("winHide");
      }
    }
  }
};
</script>