export default {
    methods: {
        getGames() {
            this.getData(`/clients`, response => {
                this.$store.dispatch('setGames', response.data)
                if (response.data && response.data.length) {
                    this.$store.dispatch('setVideo', response.data[0])
                }
            })
        },
        getGame(slug, callback) {
            this.getData(`/${slug}`, response => {
                callback(response)
                this.getUserProfile()
            })
        },
        getUserProfile(callback) {
            this.getData(`/account`, response => {
                let data = response.data
                this.$store.dispatch('setUser', data)
                localStorage.setItem('user', JSON.stringify(data))
                if (callback) callback()
            }, () => {
                // window.location.reload()
            })
        },
        getOutlets(mode) {
            this.getData(`/lotto/wallet/${mode}/outlets`, response => {
                response['mode'] = mode
                this.$store.dispatch('setOutlets', response)
            })
        },
        getWalletHistory(mode, page = 1, newData = false) {
            this.getData(`/lotto/wallet/${mode}?limit=2&page=${page}`, response => {
                response['mode'] = mode
                if (newData) response['new_data'] = true
                this.$store.dispatch('setWallet', response)
            })
        }
    }
}