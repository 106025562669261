const isApp = !!window.cordova

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import VueNumeralFilter from 'vue-numeral-filter'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import axios from 'axios'

//Set Vue
sync(store, router)
Vue.use(VueNumeralFilter)
Vue.config.productionTip = false

// Axios set
window.axios = axios
axios.defaults.timeout = window.SETTINGS.REQUEST_TIMEOUT
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['X-Game'] = 'CFG'

//Boot app
const app = new Vue({
  data: {
    status: false,
    app: false,
    reset: null
  },
  methods: {
    init() {
      this.status = true
      this.app = isApp
    },
    dispatch(event) {
      this.$store.dispatch('appEvent', event)
    },
    resume() {
      this.dispatch('resume')
      window.location.reload()
    },
    pause() {
      this.dispatch('pause')
    },
    online() {
      this.dispatch('online')
    },
    offline() {
      this.dispatch('offline')
    }
  },
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (isApp) {
  const mobile = {
    initialize() {
      this.bindEvents()
    },
    bindEvents() {
      document.addEventListener('deviceready', this.onDeviceReady, false)
    },
    onDeviceReady() {
      app.init()
      document.addEventListener('resume', this.onResume, false)
      document.addEventListener('pause', this.onPause, false)
      document.addEventListener("offline", this.onOffline, false);
      document.addEventListener("online", this.onOnline, false);
    },
    onResume() {
      app.resume()
    },
    onPause() {
      app.pause()
    },
    onOnline() {
      app.online()
    },
    onOffline() {
      app.offline()
    }
  }

  mobile.initialize()
}