<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-toolbar fixed flat color="#283a67" dark>
      <v-toolbar-title>Lotto Results</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click.stop="refresh()">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </v-toolbar>
    <!-- <page-header title="Lotto Results" /> -->
    <v-sheet tile colot="#fff" class="elevation-0 pb-2">
      <result-list />
    </v-sheet>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import ResultList from "./../components/ResultList";

export default {
  mixins: [mixin],
  components: {
    ResultList,
  },
  methods: {
    refresh() {
      this.$router.go(0);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.games.length) this.getGames();
    });
  },
};
</script>

<style lang="sass">

.pcso-result-title, .pcso-result-prize
  font-size: .95em
.pcso-result-title
  font-weight: 500
.pcso-ball
  color: #fff !important
</style>