<template>
  <div style="background: #fff: margin: 0; padding: 0">
    <div v-if="hasList">
      <div class="mb-3">
        <v-layout class="list-history px-3" v-for="(transaction, index) in lists" :key="index">
          <v-flex xs4 class="py-3 pr-4 text-left">
            {{ formatMoney(parseFloat(transaction.amount)) }}
            <div class="with-info text-capitalize grey--text">{{ transaction.player_type_label }}</div>
          </v-flex>
          <v-flex xs4 class="py-3 text-left">
            <template v-if=" transaction.user1 && transaction.user1.masked_email">
              {{ transaction.user1.masked_email }}
              <div class="with-info text-capitalize grey--text">{{ transaction.user1.name }}</div>
            </template>
            <template v-else>
              System
              <div class="with-info text-capitalize grey--text">{{ transaction.remarks }}</div>
            </template>
          </v-flex>
          <v-flex xs4 class="py-3 text-right">
            {{ transaction.date_label }}
            <div class="with-info grey--text">{{ transaction.time_label }}</div>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <!-- <div  class="text-center pt-4 text-lose"> -->

    <v-card v-else-if="lists < 1" flat color="grey lighten-5">
      <div class="" v-if="loading">
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </div>
      <div class="" v-else="">
        <p class="text-center">You have no transactions yet</p>
      </div>
    </v-card>
    <!-- <small>{{ loading ? 'Loading... test' : 'You have no transactions yet' }}</small> -->
    <!-- </div> -->
    <div v-if="hasMore" class="text-center mb-8">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </div>
</template>

<script>
import mixins from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";

export default {
  mixins: [mixins, list, helpers],
  data: () => ({
    dataUrl: "/lotto/wallet/transactions",
  }),
};
</script>