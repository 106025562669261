export default {
    data: () => ({
        pusher: null,
        channel: {
            draw: null,
            user: null,
            clients: null
        }
    }),
    computed: {
        isGame () {
            return this.$route.name === 'play'
        },
        user () {
            return this.$store.state.user || false
        }
    },
    methods: {
        initPusher () {
            // eslint-disable-next-line no-undef
            this.pusher = new Pusher(window.SETTINGS.PUSHER_APP_KEY, {
                cluster: window.SETTINGS.PUSHER_APP_CLUSTER,
                forceTLS: true
            })

            this.pusher.connection
                .bind('connected', () => {
                   this.$store.dispatch('overlayHide')
                   console.log('pusher connected')
                })
                .bind('disconnected', () => {
                    this.disconnectOverlay()
                })
                .bind('unavailable', () => {
                    this.disconnectOverlay()
                })

            this.subscribe()
        },
        subscribe () {
            console.log('subscribe')
            this.channel.draw = this.pusher.subscribe(`${window.SETTINGS.ENVIRONMENT}.lotto.draws`)
                .bind('draw.updated', () => {
                   console.log('draw update')
                   // this.$store.dispatch("setGames", data);
                })

            this.channel.clients = this.pusher.subscribe(`${window.SETTINGS.ENVIRONMENT}.clients`)
                .bind('video.updated', data => {
                    console.log('video update')
                    this.$store.dispatch('setVideo', data)
                })

            this.channel.user = this.pusher.subscribe(`${window.SETTINGS.ENVIRONMENT}.users.${this.user.id}`)
                .bind('user.updated', data => {
                    console.log('user update')
                    this.$store.dispatch('setUser', data)
                    localStorage.setItem('user', JSON.stringify(data))
                    if (this.isGame) this.$store.dispatch('getHistory')
                    if (data.message) this.$store.dispatch('prompt', { message: data.message })
                    if (data.win_amount) this.$store.dispatch('win', { message: data })
                })
                .bind('user.credits', data => {
                    console.log('user credits')
                    this.user.credits = data.credits
                    this.$store.dispatch('setUser', this.user)
                    if (data.message) this.$store.dispatch('prompt', { message: data.message })
                    localStorage.setItem('user', JSON.stringify(this.user))
                })
                .bind('user.notify', data => {
                    console.log('user notify')
                    this.$store.dispatch('prompt', { message: data })
                })
        },
        disconnectOverlay() {
            if (this.inApp) {
                this.$store.dispatch('overlay', {
                    message: '<strong>You are disconnected or offline</strong><br>Trying to reconnect...',
                    button: false
                })
            }
        }
    },
    watch: {
        user () {
            if (this.user && this.user.id) { 
                if (this.pusher === null) this.initPusher() 
            }
        }
    }
}