<template>
  <div class="embed-container">
    <iframe v-if="embedId" :src="embedId" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  props: ['video'],
  computed: {
    embedId () {
      if (this.video && this.video.video_url) {
          let id = this.video.video_url.split("/")
          return 'https://www.youtube.com/embed/' + id[id.length - 1].replace('watch?v=','')
      }
      return false
    }
  }
}
</script>

<style>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>