<template>
  <v-container dark class="fill-height" fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="main-form" @submit="submit()">
      <div class="text-center mb-4">
        <img src="/imgs/logo.png" class="reg-logo mb-0" />
        <h4>Verification</h4>
        <p class="mt-4 auth-note" v-if="credentials.mobile">
          Please verify your mobile number by providing the One Time Pin we sent to
          <strong>{{ credentials.mobile }}</strong>
        </p>
      </div>
      <v-text-field
        dark
        v-model="credentials.pin"
        label="One Time Pin"
        type="number"
        :rules="[rules.required]"
        :error-messages="error || ''"
        @keypress.enter="submit()"
        required
      ></v-text-field>
      <div class="text-center mt-4">
        <v-btn color="#fcd016" class="mb-6 elevation-0" rounded @click="submit()" :loading="busy" block>
          Verify
        </v-btn>
        <p class="mt-5 auth-note mb-1">Didn't recieve your One Time Pin?</p>
        <v-btn color="warning" text rounded class="mb-2" @click.stop="reSend()" :loading="busy" :disabled="disableResend">
          Resend pin
        </v-btn>
        <br />
        <v-btn dark text rounded @click.stop="reRegister()">Back</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    withMobile: true,
    disableResend: false,
    credentials: {
      pin: null,
      mobile: null
    }
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) this.verify(this.credentials);
    },
    reRegister() {
      localStorage.removeItem("verified");
      this.$router.push({ name: "login" });
    },
    reSend() {
       this.disableResend = true
      this.reSendPin(this.credentials)
      setTimeout(() => {
        this.disableResend = false
      }, 30000);
    }
  },
  mounted() {
    this.$nextTick(() => {
      let registered = localStorage.getItem("user");
      registered
        ? (this.credentials.mobile = JSON.parse(registered).mobile)
        : (this.withMobile = false);
    });
  }
};
</script>