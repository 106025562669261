<template>
  <div>
    <v-navigation-drawer v-model="drawer" app dense clipped>
      <v-list v-if="user" class="ml-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">{{ user.name || null }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.mobile || null }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item link @click="goTo('/')">
          <v-list-item-action>
            <v-icon>mdi-play</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Play Lotto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/results')">
          <v-list-item-action>
            <v-icon>mdi-trophy</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Results</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/account')">
          <v-list-item-action>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="goTo('/help')">
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader class="ml-2">ACCOUNT</v-subheader>
        <v-list-item link @click="goTo('/settings')">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <div v-if="showLogo" class="toolbar-logo ml-1" @click="reload()">
        <img src="/imgs/logo.png" height="24" />
      </div>
      <v-toolbar-title v-else class="pl-1">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div 
        id="credits" 
        class="mr-3" 
        @click.stop="user.is_si_player ? $store.dispatch('showWalletModal') : false"
        v-if="$route.name !== 'withdraw'">
        <h3 v-if="user && user.credits !== null">
          <!-- <v-icon v-if="user.is_si_player" size="16" color="#ffca34" class="add-credits-icon mr-1">
            mdi-plus-circle
          </v-icon>  -->
          {{ formatMoney(user.credits) }}
        </h3>
      </div>
      <slot name="reload"></slot>
    </v-app-bar>
  </div>
</template>

<script>
import helpers from "./../mixins/helpers";

export default {
  mixins: [helpers],
  props: ["title", "showDrawer"],
  data: () => ({
    drawer: null
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    showLogo() {
      return window.SETTINGS.SHOW_LOGO;
    }
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path }).catch(() => {
        this.drawer = false;
      });
    },
    logout() {
      this.drawer = false;
      this.$emit("logout");
    },
    reload() {
      window.location.reload();
    }
  },
  watch: {
    showDrawer() {
      this.drawer = this.showDrawer;
    }
  }
};
</script>

<style lang="sass">
.v-toolbar__title
  font-size: 1rem !important
  line-height: 1rem !important

.toolbar-logo
  margin-top: 7px
  overflow: hidden

.add-credits-icon
  margin-top: -2px

@media screen and (max-width: 374px)
  .toolbar-logo
    width: 24px

#toolbar-title
  font-size: .85em

#credits
  font-size: .9em
  // color: #ffca34
  h3
    font-weight: 700
</style>