<template>
  <v-container class="pa-3 ma-0" fluid>
    <ol>
      <li class="mb-3">Minimum bet is <span class="yellow--text">100</span></li>
      <li class="mb-3">Betting window is <span class="yellow--text">3 to 5 mins</span>. 
        There are 3 game status; 
        <span class="yellow--text">OPEN</span>, 
        <span class="yellow--text">LAST CALL</span> and 
        <span class="yellow--text">CLOSED</span>. 
        Once betting is CLOSED, bets will not be accepted</li>
      <li class="mb-3">Payout style: <span class="yellow--text">Sports betting.</span></li>
      <li class="mb-3">Payout computation (odd):
        % based on total bet per side
        Example: 100 bet at 180% wins 180</li>
      <li class="mb-3">Jackpot(YELLOW) takes
        (TOTAL bets x 62.52%) / TOTAL Yellow Bets</li>
      <li class="mb-3">In case of CANCELLED rounds, all bets will be returned</li>
    </ol>
  </v-container>
</template>

<script>
import mixin from "./../mixins";

export default {
  mixins: [mixin]
};
</script>