<template>
  <div class="pcso-ball-cont" v-if="numbers">
    <v-avatar
      class="pcso-ball mr-1 mb-1 body-2"
      size="25"
      v-for="(num, i) in combination"
      :class="color(num)"
      :key="i"
      >{{ num }}</v-avatar
    >
  </div>
</template>

<script>
export default {
  props: {
    numbers: {
      type: Array,
      default: null,
    },
    roll: {
      type: Number,
      default: 0,
    },
    is_rambol: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    color(num) {
      if (num === "R1" || num === "R4") {
        return "roll";
      }
      if (num === "R") {
        return "rambol";
      }
    },
  },
  computed: {
    combination() {
      let combi = [];

      if (this.roll === 1) combi = ["R1"];
      this.numbers.forEach((number) => {
        combi.push(number);
      });

      if (this.roll === 4) combi.push("R4");

      if (this.is_rambol == 1) combi.push("R");
      return combi;
    },
  },
};
</script>
<style lang="sass" scoped></style>
