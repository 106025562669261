<template>
  <v-container class="fill-height pa-0 ma-0 white align-center success-cont" fluid color="white">



    <div class="mx-auto">
      <div v-if="!allSoldOut">
          <v-avatar transition="scale-transition" origin="center center" color="success" size="90">
            <v-icon class="white--text" size="68">mdi-check</v-icon>
          </v-avatar>
          <h4 class="my-4">
            <span v-if="hasSoldOut">Some </span>
            Bet submitted successfully!
          </h4>
          
          <v-btn v-if ="!hasSoldOut && !allSoldOut" small color="primary" class="mr-2 elevation-0" @click.stop.prevent="$router.push({ name: 'play' })">
            Back to games
          </v-btn>
          <v-btn v-if="hasSoldOut"  small color="primary" class="mr-2 elevation-0" @click.stop.prevent="$router.go(-1)">
            Back to games
        </v-btn>

          <v-btn small color="primary" class="elevation-0" @click.stop.prevent="$router.push({ path: '/account/1' })">
            View Tickets
          </v-btn>
      </div>
  
      <div v-if="hasSoldOut" class="mt-4">
        <v-avatar v-if="allSoldOut" transition="scale-transition" origin="center center" color="warning" size="90">
            <v-icon class="white--text" size="68">mdi-close</v-icon>
        </v-avatar>

        <h4 class="my-4">Sold Out Combinations</h4>
        <div v-for ="(board, index) in boards" :key="index" > 
            <div class="d-flex align-center justify-center" v-if="board.is_soldout" >
                <div v-for="(combination, index) in explodeCombination(board)" :key="index">
                  <v-avatar 
                  :color="combination === 'R1' || combination === 'R4' ? 'grey' : 'red darken-1'"
                    size="30" class="mr-1 mb-1">
                    <span class="white--text body-1">{{ combination }}</span>
                  </v-avatar>
                </div>
            </div>
        </div>
        <div class="d-flex justify-space-between mt-4" >
          <span>Amount: </span>
          <span> {{ formatMoney(totalAmount) }} </span>
        </div>
        <div class="d-flex justify-space-between" >
          <span>Sold Out </span>
          <span> <v-icon class="red--text">mdi-minus</v-icon> {{ formatMoney(totalSoldOut) }} </span>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between mt-4 mb-4" >
          <span>Amount Paid: </span>
          <span> {{ formatMoney(totalAmount-totalSoldOut)  }} </span>
        </div> 
        <v-btn v-if="allSoldOut"  small color="primary" class="mr-2 elevation-0" @click.stop.prevent="$router.go(-1)">
            Back to games
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import helpers from "./../mixins/helpers";
import _ from 'lodash';


export default {
  mixins: [mixin,helpers],
  data() {
    return {

      message: "Successfully added bet(s).",
      boards: [
        
      ]
    }
  },
  methods:{
    explodeCombination(board){

      let combination = board.combination;

      if (board.roll === 1) combination = 'R1,' + combination;
      if (board.roll === 4) combination += ',R4' ;

        return _.split(combination, ',');
    },
  },
  computed:{

    allSoldOut(){
      return this.boardSoldout == this.boards.length;
    },
    boardSoldout(){
      return _.filter(this.boards, board => {
        return board.is_soldout
      }).length;
    },
    hasSoldOut(){
      return _.filter(this.boards, board => { return board.is_soldout }).length > 0;
    },
    totalAmount(){
      return _.sumBy(this.boards, board => {
        return board.amount ?? 0;
      })
    },
    totalSoldOut(){
      return _.sumBy(this.boards, board => {
        return board.is_soldout ? (board.amount ?? 0) : 0;
      })
    }
    
  },
  mounted() {
    this.$store.dispatch("overlayHide");

    var response = JSON.parse(localStorage.getItem('bet-success'));
     this.boards = response.data.boards;
     this.message = response.data.message;
  },
};
</script>

<style scoped>
.success-cont {
  /* height: 100vh; */
  /* align-content: center; */
  text-align: center;
}
</style>