<template>
  <div>
    <v-dialog light v-model="prompt.show" max-width="300">
      <v-card class="prompt-modal">
        <div class="prompt-content py-5 px-4 text-center">
          {{ prompt.message }}
        </div>
        <v-btn class="modal-btn" block color="primary elevation-0" light @click="prompt.show = false">OK</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import helpers from "./../mixins/helpers.js";
export default {
  mixins: [helpers],
  computed: {
    prompt: {
      get() {
        return this.$store.state.prompt;
      },
      set(val) {
        val || this.$store.dispatch("promptHide");
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.prompt-modal
  .prompt-content
    overflow: hidden
    position: relative
    .prompt-animation
      width: 100%
      max-width: 300px
      margin-top: -25%
      .prompt-result
        margin-top: -25%
</style>