<template>
  <v-container class="pa-0 ma-0" fluid>
    <game-header v-if="game" :game="game" :slug="$route.params.slug" />

    <number-selector
      v-if="game"
      :game="game"
      :selections-per-set="game.settings.length"
      :unique-numbers-only="game.settings.unique_numbers_only"
      :minimum-number="game.settings.min_digit"
      :maximum-number="game.settings.max_digit"
      :is-quick-pick="$route.params.pick === 'quick-pick'"
      :key="$route.params.pick"
      @number-selected="storeSelectedSet"
      @submit-play="setIsSubmit"
    />

    <v-row justify="center">
      <v-dialog v-model="confirmationDialog" max-width="290">
        <v-card>
          <v-card-title class="body-1 justify-center">
            <div class="text-center">Do you want to leave?</div>
          </v-card-title>
          <v-card-text class="body-2 mb-0">You have unsubmitted numbers!</v-card-text>

          <v-card-actions class="justify-center">
            <v-btn color="red darken-1" text @click="cancel()">Cancel</v-btn>

            <v-btn color="green darken-1" text @click="leave()">Leave</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import game from "./../mixins/game";
import GameHeader from "./../sections/GameHeader";
import NumberSelector from "./../components/NumberSelector";
import _ from "lodash";

export default {
  mixins: [mixin, game],
  components: {
    NumberSelector,
    GameHeader,
  },
  data() {
    return {
      game: null,
      confirmationDialog: false,
      continue: false,
      resolve: null,
      setSelections: null,
      isSubmit: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasSelectedNumbers && !this.isSubmit) {
      this.open().then(() => {
        next(this.continue);
      });
    } else {
      next();
    }
  },
  methods: {
    open() {
      this.confirmationDialog = true;

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    leave() {
      this.resolve(true);
      this.continue = true;
      this.confirmationDialog = false;
    },
    cancel() {
      this.continue = false;
      this.confirmationDialog = false;
    },
    getSettings() {
      this.getGame(this.$route.params.slug, (data) => {
        data ? (this.game = data) : this.$router.push({ name: "play" });
      });
    },
    storeSelectedSet(setSelections) {
      this.setSelections = setSelections;
    },
    setIsSubmit(isSubmit) {
      this.isSubmit = isSubmit;
    },
  },

  computed: {
    hasSelectedNumbers() {
      return (
        _.filter(this.setSelections, (item) => {
          return _.filter(item.data, (number) => {
            return number.value !== null;
          }).length;
        }).length > 0
      );
    },
  },
  mounted() {
    this.getSettings();
  },
};
</script>