<template>
  <v-container class="pa-0 ma-0" fluid>
    <game-header :game="game" :slug="$route.params.slug" />
    <v-tabs fixed-tabs slider-color="#273b67" v-model="tab">
      <v-tab>PLAY</v-tab>
      <v-tab>RESULTS</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="pcso-draws-list-container elevation-1 mb-2">
          <h3 class="px-4 py-3">
            <small v-if="loading">Getting draw schedules...</small>
            <small v-else>Select a Draw</small>
          </h3>
          <div v-if="game && game.schedules">
            <v-card
              flat
              class="pcso-draws-list px-4 py-3 d-flex"
              v-for="(draw, index) in game.schedules"
              :key="index"
              @click.stop="select(draw)"
            >
              <div class="flex-grow-1">
                <div class="pcso-draw-prize">
                  <strong>{{ draw.label }}</strong>
                </div>
                <div class="pcso-draw-id">Draw</div>
              </div>
              <div class="flex-grow-1 text-right">
                <div class="pcso-draw-date">{{ draw.date }}</div>
                <div class="pcso-draw-day">{{ draw.day }}</div>
              </div>
            </v-card>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="elevation-1 mb-2">
          <result-list />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog light class="pcso-select-type-modal" v-model="openModal" max-width="380">
      <v-card>
        <div class="text-center pcso-modal-title pa-3">
          How do you want to choose
          <br />your number?
        </div>
        <v-sheet color="#d63b3a" tile>
          <v-card
            @click.stop="selectType(selection)"
            color="#cb3131"
            dark
            tile
            class="pa-3 elevation-0 pcso-selections-list"
            v-for="(selection, index) in selectionTypes"
            :key="index"
          >
            <img :src="'/imgs/' + selection.image" width="48" class="float-left mr-3" />
            <div>
              <strong>{{ selection.title }}</strong>
              <br />
              <small>{{ selection.description }}</small>
            </div>
          </v-card>
        </v-sheet>
        <v-card-actions class="pa-0">
          <v-btn @click.stop="openModal = false" text block tile>CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import ResultList from "./../components/ResultList";
import GameHeader from "./../sections/GameHeader";

export default {
  mixins: [mixin],
  components: {
    ResultList,
    GameHeader,
  },
  data() {
    return {
      tab: null,
      openModal: false,
      game: null,
      selectionTypes: [
        {
          id: 1,
          image: "quick.png",
          title: "Lucky Pick",
          type: "quick-pick",
          description: "Computer generated numbers",
        },
        {
          id: 2,
          image: "manual.png",
          title: "Manual Pick",
          type: "manual-pick",
          description: "Manually set you number combination",
        },
      ],
    };
  },
  methods: {
    select(draw) {
      this.openModal = true;
      localStorage.setItem("draw", JSON.stringify(draw));
    },
    selectType(selection) {
      this.$router.push({ path: '/game/' + this.$route.params.slug + "/" + selection.type});
    },
    getSettings() {
      this.getGame(this.$route.params.slug, (data) => {
        if (data) {
          this.game = data;
          localStorage.setItem("game", JSON.stringify(data));
        } else {
          this.$router.push({ name: "play" });
        }
      });
    },
  },
  mounted() {
    localStorage.removeItem("draw");
    this.getSettings();
  },
};
</script>

<style lang="sass">
.pcso-draws-list-container
    // background: #f8f8f8
.pcso-draws-list
  .pcso-draw-id, .pcso-draw-day
    color: gray
    font-size: .8em
    .pcso-draw-prize
      font-size: 1.1em

.pcso-modal-title
  color: #9e2e2f !important
  font-size: 1.15em !important
  font-weight: 500

.pcso-selections-list
  &:first-child
    border-bottom: 1px solid #000
    margin-bottom: 1px
</style>