<template>
  <v-app id="inspire">
    <nav-bar
      v-if="inApp && showNav"
      :title="navTitle"
      show-drawer="drawer"
      @logout="logout()"
    >
      <template v-slot:reload>
        <v-progress-circular
          v-if="loading || initReload"
          class="loading-circle"
          size="24"
          indeterminate
          color="orange"
        />
        <v-icon v-else @click.stop.prevent="reload()">
          mdi-refresh
        </v-icon>
      </template>
    </nav-bar>
    <v-content
      :class="{
        'main-pcso-content': inApp,
        'pcso-games-list': isGame,
        'other-pcso-content': !inApp,
      }"
    >
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <main-tool-bar v-if="inApp" />
      <snackbar />
      <win-overlay />
      <prompt />
    </v-content>
    <overlay />
  </v-app>
</template>

<script>
import routes from './mixins/routes'
import global from './mixins/global'
import pusher from './mixins/pusher'
import data from './mixins/data'
import fetchers from './mixins/fetchers'
import NavBar from './components/NavBar.vue'
import Snackbar from './components/Snackbar.vue'
import MainToolBar from './components/MainToolBar.vue'
import Overlay from './components/Overlay.vue'
import WinOverlay from './components/WinOverlay.vue'
import Prompt from './modals/Prompt.vue'

export default {
  mixins: [
    routes, 
    global, 
    pusher, 
    data, 
    fetchers
  ],
  components: {
    NavBar,
    Snackbar,
    MainToolBar,
    Overlay,
    WinOverlay,
    Prompt
  }
}
</script>

<style lang="sass">
@import './sass/colors.scss'

.theme--light.v-application
  background: none

.main-pcso-content
  padding-bottom: 60px !important
  background: #e5e3e9

.other-pcso-content
  background: #273b67

.pcso-games-list
  background: #e5e3e9

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  apperance: none
  margin: 0

h3
  font-weight: 400
  font-size: 1.1em

.main-snackbar
  margin-top: 48px !important

.circle
  height: 12px
  width: 12px
  background-color: #272727
  border-radius: 50%
  display: inline-block

.page-content
  background: #1d1d1d

.clear
  display: block
  float: none
  clear: both

.p-relative
  position: relative !important

.no-underline
  text-decoration: none !important

.fab-bottom-padding
  padding-bottom: 60px !important

.v-speed-dial__list
  padding: 0 6px !important
  .v-btn
    border: 2px solid orange

.fab-btn
  overflow: hidden
  img, .v-btn__content
    width: 100% !important

.toolbar-header
  font-size: 1.2em
  display: inline-block

//Auth: Login, Registration, Verify, Password Reset

.main-form
    margin: 0 auto
    width: 100%
    max-width: 360px
    padding-right: 30px
    padding-left: 30px
    h4
      font-weight: 500
      color: #fff

.login-logo
  max-width: 140px
  margin: 0 auto 10px

.auth-note
  font-size: .85em !important
  color: #f8f8f8

.reg-logo
  max-width: 100px !important
  margin: 0 auto 10px

.terms
  font-size: .9em
  h3
    margin-bottom: 1em
    font-weight: 600

.terms-link
  color: #333 !important

.error--text
  .terms-link
    color: #ff5252 !important

.pcso-game-logo
  width: 62px
  height: 62px
  overflow: hidden

  img
    width: 100%
    margin: 0 auto 0 !important

.pcso-keyboard
  background-color: #d4d4d4
  padding: 4px
  padding-bottom: 40px
  .key
    width: 10%
    display: inline-block
    float: left
    div
      margin: 2px
      border-radius: 4px
      padding: 0
      background: #f3f2f2
      text-align: center
      line-height: 2.6em
      font-size: 14px
      text-shadow: 1px 1px 1px #fff
      font-weight: 500
      color: #444
      box-shadow: 1px 1px 2px rgba(0,0,0,.1)

//Wallet: Deposit, Withdraw

.outlet-details
  font-size: .96em
  line-height: 1.5em
  h3
    font-weight: 600
  .phone-number
    letter-spacing: 0.1em
  .amount
    color: #ffca34 !important
    display: inline
  .outlet-icon
    width: 64px
    height: 56px
    margin: -40px auto 16px auto
    border-radius: 4px
    overflow: hidden
    img
      width: 100%
  .small
    font-size: .92em
  .instructions
    font-size: .92em
    border-bottom: 1px solid rgba(255,255,255,0.3)
    padding: 1em 0 1em
  .instructions:first-child
    padding-top: 0 !important
  .instructions:last-child
    padding-bottom: 0
    border-bottom: none

.notes
  background: #bbdefb !important
  color: #0d47a1 !important
  font-size: .9em

.wallet-form
  .v-input
    margin-bottom: .8em !important

.list-history
  font-size: 1em
  border-bottom: 1px solid #f2f2f2
  .with-amount
    font-size: 1em
  .with-status 
    font-size: .8em
  .with-info
    font-size: .8em
  .dep-info
    opacity: .8
  a
    text-decoration: none

.cancelled-status
  color: red

.processing-status, .refunded-status
  color: #FFB300

.paid-status, completed-status
  color: #7CB342

.bg-white
  background: #fff !important


.results-comb
  .pcso-ball
    background-color: #556a9a !important
  .rambol
    background-color: #1e84cc !important
  .roll
    background-color: #a9a9a9  !important
.tickets-comb
  .pcso-ball
    background-color: #ff4f4f !important
  .rambol
    background-color: #1e84cc !important
  .roll
    background-color: #a9a9a9  !important

.ticket-dialog
  .break-circle
    background: #e5e3e9 !important
  .border-ni
    background-image: linear-gradient(#e5e3e9 33%, rgba(255, 255, 255, 0) 0%) !important
</style>