<template>
    <div class="d-flex pcso-toolbar">
        <div 
            @click.stop.prevent="$route.name !== 'results' ? $router.push({ name: 'results' }) : null"
            class="flex-grow-1 pcso-toolbar-buttons pl-3"
        >
            <v-icon color="#fff" size="32" class="mb-1">mdi-trophy</v-icon>
            <div>Results</div>
        </div>
        <div 
            @click.stop.prevent="$route.name !== 'play' ? $router.push({ name: 'play' }) : null"
            class="flex-grow-1 pcso-toolbar-buttons"
        >
            <img src='/imgs/logo.png' class="pcso-toolbar-logo" :class="{ 'rotating' : loading }" />
            <div>Games</div>
        </div>
        <div 
            @click.stop.prevent="$route.name !== 'account' ? $router.push({ name: 'account' }) : null"
            class="flex-grow-1 pcso-toolbar-buttons pr-3"
        >
            <v-icon color="#fff" size="32" class="mb-1">mdi-account-circle</v-icon>
            <div>Account</div>
        </div>
    </div>
</template>

<script>
export default { 
  computed: {
    loading() {
      return this.$store.state.loading
    }
  }
}
</script>

<style lang="sass">
    .pcso-toolbar
        color: #fff
        background: #273b67
        position: fixed
        max-width: 768px
        bottom: 0
        width: 100%
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4)
        z-index: 1000
        .pcso-toolbar-buttons
            text-align: center
            padding-top: .8em
            padding-bottom: .6em
            font-size: .8em
            // &:hover
            //     background: rgba(0, 0, 0, .1)
            .pcso-toolbar-logo
                width: 70px
                border: 6px solid #273b67
                border-radius: 50%
                margin-top: -33px
                margin-bottom: -6px
</style>
<style lang="css">
    @keyframes rotating {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
    .rotating {
        animation: rotating 2s linear infinite;
    }
</style>