<template>
  <v-container
    class="pa-0 ma-0 play-page"
    fluid
    v-if="loggedIn && user && games"
  >
    <div class="p-relative elevation-2">
      <player-youtube v-if="yt" :video="video" />
      <player-vjs v-else :video="video" :cant-stream="user.is_stream_blocked" />
    </div>
    <games-lists :games="filteredGames" />
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import game from "./../mixins/game";
import PlayerVjs from "./../sections/PlayerVJS";
import PlayerYoutube from "./../sections/PlayerYoutube";
import GamesLists from "./../components/GamesLists";

export default {
  mixins: [mixin, game],
  computed: {
    yt() {
      if (this.video && this.video.video_url) {
        return /(youtu)/i.test(this.video.video_url);
      }
      return false;
    },
    filteredGames() {
      return this.games.filter((game) => game.is_public === 1);
    },
  },
  components: {
    PlayerVjs,
    PlayerYoutube,
    GamesLists,
  },
  mounted() {
    this.getGames();
  },
};
</script>

<style lang="sass">
.play-page
  background: #e5e3e9
  h4
    font-weight: 500
.main-player
  width: 100%
  height: 0
  padding: 0 0 56.25% 0
  background-color: #000000
</style>
