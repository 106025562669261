<template>
  <v-container class="pa-0 pb-8 ma-0 bg-white" fluid>
    <page-header title="Add Credits" bg="or.png" back-button="account" />
    <div class="pa-3 pb-1 bg-white">
    <template v-if="user.type !== 'sub_coor'">
    <div class="mt-1 mb-4">
      <v-select
        v-if="outlets"
        v-model="deposit.outlet"
        :items="outlets"
        label="Select Outlet"
        item-text="name"
        item-value="id"
        hide-details
        class="mb-4"
        @change="selectOutlet"
        :loading="loading"
        :disabled="loading"
      ></v-select>
      <v-btn 
        block 
        rounded
        color="warning" 
        class="elevation-0"
        router
        to='/cash-ins'
      >
          VIEW CASH-IN REQUESTS
      </v-btn>
    </div>
    <br />
    <v-sheet v-if="outlet && !outlet.gateway" color="teal" dark class="pa-3 pb-4 mb-3 text-center outlet-details">
      <div class="outlet-icon elevation-2">
        <img :src="getLogo(outlet.id)" />
      </div>
      <div v-if="outlet.receivers.length > 0 || outlet.id === 'si'">
        <div v-if="outlet.id !== 'si'">
          <h4>Send a minimum of</h4>
          <div>
            <h3 class="amount" v-html="formatMoney(outlet.minimum)"></h3>
          </div>
          <div>to any of the numbers below</div>
          <div v-for="(rec, index) in outlet.receivers" :key="index">
            <v-sheet
              color="white"
              class="mt-3 py-2 elevation-1"
              light
              v-if="rec.name"
            >
              <template v-if="rec.address">
                <h4>{{ rec.name }}</h4>
                <div class="small">Address: {{ rec.address }}</div>
                <div class="small">Phone: <span class="phone-number">{{ rec.number }}</span></div>
              </template>
              <template v-else>
                <h4 class="phone-number">{{ rec.number }}</h4>
                <div class="small">{{ rec.name }}</div>
              </template>
            </v-sheet>
          </div>
        </div>
        <template v-if="instructions[outlet.id]">
          <div
            class="instructions"
            v-for="(instruction, index) in instructions[outlet.id]"
            :key="index"
          >{{ instruction }}</div>
        </template>
      </div>
      <div v-else class="mt-3">{{ outlet.name }} is currently unavailable</div>
    </v-sheet>
    <v-sheet
      v-if="outlet && outlet.gateway"
      color="#f2f2f2"
      class="mb-4 pa-3 pb-4 text-center outlet-form"
    >
      <div v-if="gateway && gateway.url">
        <p class="mt-3">{{ gateway.message }}</p>
        <v-btn 
          outline 
          text 
          block 
          color="primary" 
          :loading="busy" 
          @click="openGateway(gateway.url)"
        >
          {{ gateway.url }}
        </v-btn>
      </div>
      <div v-else>
        <h3>Deposit Form</h3>
        <v-form
          ref="form"
          v-model="valid2"
          lazy-validation
          class="mb-5 wallet-form"
          @submit="submit('gateway')"
        >
          <v-text-field
            :label="amountLabel"
            type="number"
            placeholder="0"
            v-model="siAmount"
            :rules="[rules.required, depositAmountRule]"
            :error-messages="error && error.amount ? error.amount[0] : ''"
            :loading="busy"
          />
        </v-form>
        <v-btn block rounded color="primary" :loading="busy" @click="submit('gateway')">SUBMIT</v-btn>
      </div>
    </v-sheet>
    <v-sheet
      v-else-if="outlet && outlet.receivers.length > 0"
      color="#f2f2f2"
      class="mb-4 pa-3 pb-4 text-center outlet-form"
    >
      <h3>Deposit Form</h3>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mb-5 wallet-form"
        @submit="submit()"
      >
        <v-text-field
          label="Amount"
          type="number"
          placeholder="0"
          v-model="deposit.amount"
          :rules="[rules.required, depositAmountRule]"
          :error-messages="error && error.amount ? error.amount[0] : ''"
          :loading="busy"
        />
        <template v-if="outlet.reqSender && outlet.id === 'gcash'">
          <v-text-field
            label="Sender Number"
            type="number"
            v-model="deposit.sender"
            :rules="[rules.required, rules.phone]"
            :error-messages="error && error.sender ? error.sender[0] : ''"
            :loading="busy"
          />
        </template>
        <template v-else-if="outlet.reqSender">
          <v-text-field
            label="Sender"
            v-model="deposit.sender"
            :rules="[rules.required]"
            :error-messages="error && error.sender ? error.sender[0] : ''"
            :loading="busy"
          />
        </template>
        <v-select
          v-if="outlet.reqReceiver && outlet.id === 'gcash'"
          v-model="deposit.receiver"
          :items="filterReceivers(outlet.receivers)"
          label="Receiver Number"
          item-text="number"
          item-value="number"
          :rules="[rules.required]"
          :error-messages="error && error.receiver ? error.receiver[0] : ''"
          :loading="busy"
        ></v-select>
        <v-select
          v-else-if="outlet.reqReceiver"
          v-model="deposit.receiver"
          :items="filterReceivers(outlet.receivers)"
          label="Receiver"
          item-text="name"
          item-value="name"
          :rules="[rules.required]"
          :error-messages="error && error.receiver ? error.receiver[0] : ''"
          :loading="busy"
        ></v-select>
        <v-text-field
          v-if="outlet.reqControlNumber"
          :label="outlet.controlNumberLabel"
          v-model="deposit.reference_number"
          :rules="[rules.required]"
          :error-messages="error && error.reference_number ? error.reference_number[0] : ''"
          :loading="busy"
        />
        <v-file-input
          v-if="outlet.reqAttachment"
          accept="image/*"
          label="Screenshot of actual receipt"
          v-model="deposit.attachment"
          :rules="[rules.required]"
          :error-messages="error && error.attachment ? error.attachment[0] : ''"
          :loading="busy"
        />
        <v-textarea label="Notes" auto-grow rows="1" v-model="deposit.user_notes" :loading="busy" />
      </v-form>
      <v-btn rounded block color="primary" class="elevation-0" :loading="busy" @click="submit()">SUBMIT {{ outlet.name }}</v-btn>
    </v-sheet>
    </template>
    <template v-else>
      <v-sheet light class="notes mx-1 mt-1 mb-5 pa-3">
        To buy credits, please contact your main coordinator.
      </v-sheet>
    </template>
    </div>
  </v-container>
</template>

<script>
import mixin from "./../../mixins";
import rules from "./../../mixins/rules";
import helpers from "./../../mixins/helpers";
import PageHeader from "./../../sections/PageHeader";

export default {
  mixins: [mixin, rules, helpers],
  components: {
    PageHeader
  },
  data: () => ({
    selected: null,
    siAmount: null,
    amountLabel: 'Amount',
    gateway: null,
    deposit: {
      amount: null,
      outlet: null,
      sender: null,
      receiver: null,
      reference_number: null,
      attachment: null,
      base64: null,
      user_notes: null
    },
    instructions: {
      gcash: [
        "Our GCash number change every 10 minutes. Refresh this page before sending.",
        "Fill the reference no, amount, receiver number in the form below.",
        "Attach the screenshot receipt in the attachment field."
      ],
      ml: [
        "Fill the KPTN, amount, sender, receiver in the form below.",
        "Attach the receipt in the attachment field."
      ],
      si: [
        "Enter amount of credits to transfer from Sabong International below.",
      ],
      cebuana: []
    },
    outlet: null,
    valid: true,
    valid2: true
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    outlets() {
      if (this.outletsStore) {
        return this.outletsStore.outlets.map(outlet => {
          if (outlet.id === 'si') outlet.name = 'Sabong International'
          return outlet;
        });
      }
      return [];
    },
    outletsStore() {
      return this.$store.state.outlets.deposits;
    },
    depositAmountRule() {
      if (this.outlet) {
        let amount = this.deposit.amount
        if (this.outlet.gateway) amount = this.siAmount
        return (
          amount >= parseInt(this.outlet.minimum) ||
          `Minimum amount is ${this.formatMoney(this.outlet.minimum)}`
        );
      }
      return true;
    }
  },
  methods: {
    selectOutlet(select) {
      return (this.outlet = this.outlets.find(outlet => {
        return outlet.id === select;
      }));
    },
    getLogo(outlet) {
      return `${window.SETTINGS.UI_URL}/imgs/${outlet === 'si' ? 'sabong.png' : outlet + '.jpg'}`;
    },
    openGateway(url) {
      window.open(url, '_system')
    },
    reset() {
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    filterReceivers(receivers) {
      if (receivers && receivers.length) {
        let newReceivers = []
        receivers.forEach(receiver => {
          if (receiver.name) newReceivers.push(receiver)
        })
        return newReceivers
      }
      return []
    },
    submit(game) {
      if (this.$refs.form.validate()) {
        this.busy = true;
        let url = `${window.SETTINGS.API_URL}/lotto/wallet/deposits`
        let data = new FormData();
        for (let i in this.deposit) {
          data.append(i, this.deposit[i]);
        }

        if (game === 'gateway') {
          url = url + '/redir-gateway'
          data = {
            amount: parseFloat(this.siAmount),
            outlet: 'mltpy',
            notes: ''
          }
        }
  
        window.axios
          .post(url, data, {
            "Content-Type": "multipart/form-data"
          })
          .then(response => {
            if (game === 'gateway') {
              this.gateway = response.data
            } else {
              this.$store.dispatch("prompt", { message: response.data.message });
            }
            this.reset();
          })
          .catch(error => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    }
  },
  watch: {
    outlets() {
      this.deposit.outlet = "gcash";
      if (this.outlets) this.outlet = this.selectOutlet(this.deposit.outlet);
    },
    'deposit.outlet'() {
      if (this.deposit.outlet === 'si') {
        this.getSICredits(this.user.mobile, response => {
          this.siCredits = response
          this.amountLabel = `Amount (SI Credits: ${this.siCredits})`
        })
      } else {
         this.amountLabel = `Amount`
      }
    }
  },
  mounted() {
    this.getOutlets("deposits");
  }
};
</script>