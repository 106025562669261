<template>
  <div>
    <div class="main-player">
      <video
        id="video-player-main"
        style="width: 100%; height: 100%; position: absolute; z-index: 0"
        class="video-js vjs-default-skin vjs-live"
        controls
        playsinline
      ></video>
    </div>
    <v-overlay absolute opacity="1" z-index="1" :value="video.lock_message || cantStream || !playing">
      <div class="text-center px-4" v-if="cantStream">You are not allowed to view stream</div>
      <div class="text-center px-4" v-else-if="video.lock_message">{{ video.lock_message }}</div>
      <template v-else>
        <div v-show="canPlay" @click.stop.prevent="play">
          <v-icon :size="error ? 50 : 90" color="red">mdi-play</v-icon>
        </div>
      </template>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: ["video", "cantStream"],
  data: () => ({
    player: null,
    playing: false,
    buffering: false,
    error: false,
    video_url: null,
    wss: null
  }),
  computed: {
    canPlay() {
      return this.video && this.video.video_url;
    },
    isOffline() {
      return false;
    },
    ios () {
      return window.isIOS
    }
  },
  methods: {
    initPlayer() {
      if (!this.player) {
        // eslint-disable-next-line no-undef
        this.player = videojs("video-player-main", {
          html5: {
            hls: {
              overrideNative: true
            },
            nativeVideoTracks: true,
            nativeAudioTracks: true,
            nativeTextTracks: true
          },
          liveui: false,
          autoplay: this.ios ? false : true,
          suppressNotSupportedError: true,
          notSupportedMessage: 'No live stream available',
          poster: window.SETTINGS.UI_URL + "/imgs/black.jpg",
        });
        this.player.src({
          type: "application/x-mpegURL",
          src: this.video.video_url
        });
        this.$nextTick(() => {
          this.removeControls();
          if (!this.ios) this.playing = true
        });
      } else if (this.video.lock_message || this.cantStream) {
        this.stop();
      }
    },
    checkVideo() {
      console.log("check video");
      if (this.video && this.video.video_url) {
        if (this.video.video_url !== this.video_url) {
          this.video_url = this.video.video_url;
          this.$nextTick(() => {
            this.initPlayer();
          });
        }
      }
    },
    play() {
      if (!this.player || this.cantStream) return;
      this.playing = true;
      try {
        this.player.play();
        this.player.muted(false);
      } catch (er) {
        console.log(er);
      }
    },
    stop() {
      this.playing = false;
      if (this.player) {
        this.player.muted(true);
        this.player.pause();
      }
    },
    removeControls() {
      try {
      document.getElementsByClassName("vjs-play-control")[0].remove();
      document.getElementsByClassName("vjs-mute-control")[0].remove();
      document.getElementsByClassName("vjs-volume-panel")[0].remove();
      document.getElementsByClassName("vjs-big-play-button")[0].remove();
      document.getElementsByClassName("vjs-control-bar")[0].style.paddingLeft =
        "10px";
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    video() {
      console.log("source watcher");
      this.$nextTick(() => {
        this.checkVideo();
      });
    },
    "video.lock_message"() {
      console.log("video lock + " + this.video.lock_message);
      this.video.lock_message ? this.stop() : this.play()
    }
  },
  beforeDestroy() {
    try {
      if (this.player) {
        this.stop();
        var oldPlayer = document.getElementById("video-player-main");
        // eslint-disable-next-line no-undef
        videojs(oldPlayer).dispose();
      }
      this.player = null;
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.player) this.checkVideo();
    });
  }
};
</script>