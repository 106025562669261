<template>
  <v-card
    flat
    @click="setCurrentResult"
    class="mb-4 result-card"
    :class="{ winner: ticketsWinCount > 0 }"
  >
    <v-row class="d-flex align-center">
      <v-col
        cols="3"
        class="d-flex align-center justify-center flex-column px-0"
      >
        <v-avatar tile size="35">
          <img :src="'/imgs/' + slug + '.png'" />
        </v-avatar>
        <div
          class="caption font-weight-bold grey--text text--darken-2"
          v-html="title"
        ></div>
      </v-col>
      <v-col cols="9" class="pl-0 d-flex align-center justify-space-between">
        <div>
          <combinations
            :numbers="numbers"
            :roll="roll"
            :is_rambol="is_rambol"
          />
          <div class="body-2 mt-1 grey--text text--darken-1">
            <span v-if="winAmount" class="orange--text">
              &#8369; {{ formatMoney(winAmount) }}</span
            >
            {{ drawWinnersText }}
          </div>
        </div>
        <div
          v-if="tickets"
          class=" pr-2 d-flex flex-column align-center justify-center"
        >
          <div class="caption mb-1" v-text="tickets"></div>
          <v-btn
            :class="{ success: ticketsWinCount > 0 }"
            v-if="ticketsWinCount"
            depressed
            rounded
            x-small
            >{{ ticketsWinCount }} WIN</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import helpers from "./../mixins/helpers";
import Combinations from "./../components/Combinations";

export default {
  mixins: [helpers],
  props: [
    "title",
    "clientName",
    "winAmount",
    "winner",
    "tickets",
    "drawDate",
    "numberOfDraws",
    "advanceWin",
    "ticketsWinCount",
    "numbers",
    "slug",
    "roll",
    "is_rambol",
    "boards",
  ],
  components: {
    Combinations,
  },
  data: () => ({
    resultData: {},
  }),
  methods: {
    setCurrentResult() {
      this.resultData.boards = this.boards;
      this.resultData.slug = this.slug;
      this.resultData.label = this.title;
      this.resultData.clientName = this.clientName;
      this.$emit("set-current-result", this.resultData);
    },
  },
  computed: {
    drawWinnersText() {
      if (this.winner == null) {
        return "? Winners";
      }
      return this.winner > 1
        ? this.winner + " Winners"
        : this.winner + " Winner";
    },
  },
};
</script>

<style lang="sass" scoped>
.result-card
  background-color: #f6f6f7 !important
  .pcso-ball
    background-color: #000 !important
.winner
  background-color: #e9f9e7 !important
</style>