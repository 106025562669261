export default {
    data: () => ({
        busy: false
    }),
    watch: {
        loading() {
            this.busy = this.loading
        },
        busy() {
            if (this.busy) {
                this.$store.dispatch('overlay', {
                    message: window.SETTINGS.LOADING_HTML,
                    button: false
                })
            } else {
                this.$store.dispatch('overlayHide')
            }
        }
    },
}