<template>
  <v-container class="pt-0" style="margin-bottom:350px;">
    <div class="ticket-cont">
      <v-row v-if="canSystemPlay">
        <v-col class="d-flex justify-center align-center">
          <v-btn-toggle background-color="#fff" color="deep-purple accent-3">
            <v-btn
              small
              class="pa-2 system-group-btn"
              color="white"
              v-for="systemPlay in systemPlays"
              :key="systemPlay.selections"
              @change="selectSystemPlay(systemPlay)"
              :value="systemPlay.selections"
              :class="{ 'system-active': systemPlay.selected }"
            >
              <p class="mb-0 caption">{{ systemPlay.name }}</p>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-slide-x-transition group tag="div">
        <div
          class="row num-cont align-center"
          v-for="(set, i) in setSelections"
          :key="set.number"
          :class="{ active: isCurrentSet(set) }"
        >
          <v-col
            :cols="customBetsColumn"
            class="d-flex align-center pr-0"
            :class="{
              'justify-center': !canInputBetOrCanRambol,
              'num-column': isSystemPlay,
            }"
          >
            <span class="danger--text mr-1">
              <p class="mb-0 mr-1">{{ numToCharacter(i + 1) }}</p>
            </span>
            <div class="num" v-for="(selectedNumber, i) in set.data" :key="i">
              <v-avatar
                size="30"
                class="lotto-circle"
                :class="{
                  'current-selected': selectedNumber.selected,
                  done:
                    selectedNumber.value != null && !selectedNumber.selected,
                  disabled: selectedNumber.disabled,
                }"
                @click="setCurrentSet(set, selectedNumber)"
              >
                <span class="body-2">{{ selectedNumber.value }}</span>
              </v-avatar>
            </div>
          </v-col>
          <v-col
            :cols="customCol4D"
            class="d-flex align-center justify-end bet-container"
            v-if="canInputBetOrCanRambol"
          >
            <span tile class="bet-options d-flex align-center justify-center">
              <v-btn
                x-small
                depressed
                v-if="$route.params.slug == 'lotto4d' && !isQuickPick"
                class="mr-1"
                :class="{
                  warning: set.roll == 1,
                  info: set.roll == 4,
                  default: set.roll == null,
                }"
                @click="activateRoller(set)"
              >
                <p class="mb-0 caption">{{ getIsRollLabel(set.roll) }}</p>
              </v-btn>

              <v-btn
                x-small
                depressed
                dark
                v-if="canRambol && !isQuickPick"
                :class="{
                  warning: set.isRambol,
                  'indigo light-blue darken-3': !set.isRambol,
                }"
                @click="toggleIsRambol(set)"
              >
                <p class="mb-0 caption">{{ getIsRambolLabel(set.isRambol) }}</p>
              </v-btn>
              <div class="ml-1" v-if="canInputBet">
                <div class="bet-input text-right" @click="openBetDialog(set)">
                  {{ set.bet }}
                </div>
              </div>
              <v-btn
                @click="removeBoard(set)"
                v-if="isQuickPick"
                class="ml-1"
                x-small
                depressed
              >
                <v-icon class="body-2 red--text text--lighten-2"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </span>
          </v-col>
        </div>
      </v-slide-x-transition>
    </div>
    <div class="board-draw-cont">
      <v-row class="text-center">
        <v-col cols="6" class="text-center">
          <p class="body-2 mb-2">Boards</p>
          <v-btn-toggle small>
            <v-btn small @click="removeLastSet" class="btn-to">
              <v-icon small color="error">mdi-minus</v-icon>
            </v-btn>
            <v-btn small light>
              <p class="mb-0 body-2">
                <strong>{{ selectionsCount }}</strong>
              </p>
            </v-btn>

            <v-btn small @click="addSet" class="btn-to">
              <v-icon small color="success">mdi-plus</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="6" class="text-center">
          <p class="body-2 mb-2">Draws</p>
          <v-btn-toggle>
            <v-btn
              small
              @click="advanceDrawNum--"
              class="btn-to"
              :disabled="advanceDrawNum <= 1"
            >
              <v-icon small color="error">mdi-minus</v-icon>
            </v-btn>
            <v-btn small>
              <p class="mb-0 body-2">
                <strong>{{ advanceDrawNum }}</strong>
              </p>
            </v-btn>

            <v-btn
              small
              @click="advanceDrawNum++"
              class="btn-to"
              :disabled="advanceDrawNum >= maxAdvanceBet"
            >
              <v-icon small color="success">mdi-plus</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>

    <div class="bet-details">
      <v-card class="mx-auto py-1 px-4" flat>
        <div class="d-flex align-center justify-space-between py-2">
          <span>
            <p class="mb-0 body-2">
              Number of Bets :
              <span>{{ numberOfBets }}</span>
            </p>
          </span>
          <span class>
            <p
              class="mb-0 body-1 bold font-weight-bold blue-grey--text text--darken-3"
            >
              {{ formatMoney(getTotalBets) }}
            </p>
          </span>
        </div>
        <div class="d-flex align-center justify-space-between py-2">
          <span>
            <p class="mb-0 body-2">Ticket Valid for</p>
          </span>
          <span class>
            <p class="mb-0 body-1 bold blue-grey--text text--darken-3">
              <span class="caption orange--text">X</span>
              {{ advanceDrawNum }} Draw
              <span v-if="advanceDrawNum > 1">s</span>
            </p>
          </span>
        </div>
        <v-divider class="my-0"></v-divider>
        <div class="d-flex align-center justify-space-between py-2">
          <span>
            <p class="mb-0 body-2">Sub Total</p>
          </span>
          <span class>
            <p class="mb-0 body-1 bold blue-grey--text text--darken-3">
              {{ formatMoney(getSubTotal) }}
            </p>
          </span>
        </div>
        <div class="d-flex align-center justify-space-between py-2">
          <span>
            <p class="mb-0 body-2">
              <span class="caption green--text mr-1">+</span>
              {{ getTaxLabel }}% Tax
            </p>
          </span>
          <span class>
            <p class="mb-0 body-1 bold blue-grey--text text--darken-3">
              {{ formatMoney(getTotalTax) }}
            </p>
          </span>
        </div>
        <v-divider class="my-0"></v-divider>

        <div class="d-flex align-center justify-space-between py-2">
          <span>
            <p class="mb-0">Total Amount</p>
          </span>
          <span class>
            <p
              class="mb-0 body-1 font-weight-black heading-6 blue-grey--text text--darken-3"
            >
              {{ formatMoney(total) }}
            </p>
          </span>
        </div>
      </v-card>
    </div>

    <v-col cols="12" class="pa-0 mt-4">
      <v-btn
        block
        rounded
        depressed
        color="warning"
        @click.prevent="confirmOnSubmit"
        >Submit</v-btn
      >
    </v-col>
    <v-row class="textme" v-if="!isQuickPick">
      <div class="my-floater">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="expand = !expand"
        >
          <v-icon>mdi-keyboard</v-icon>
        </v-btn>
      </div>
      <v-expand-transition>
        <v-col class="box-cont pa-0" v-show="expand">
          <div class="pcso-keyboard">
            <div class="p-relative">
              <div
                class="key"
                v-for="number in numbersSet"
                :key="number"
                @click="selectNumber(number)"
              >
                <div
                  v-ripple
                  :class="{ 'selected-number': isSelectedInCurrentSet(number) }"
                >
                  {{ number }}
                </div>
              </div>
              <div class="key float-right">
                <div v-ripple @click="goToPreviousCircle()">
                  <v-icon class="body-2">mdi-backspace</v-icon>
                </div>
              </div>
              <div class="key float-right">
                <div v-ripple @click="expand = !expand" class="body-2">OK</div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </v-col>
      </v-expand-transition>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="amountDialog">
        <v-card>
          <v-card-text class="pa-2">
            <v-row class="text-center justify-center">
              <v-col class="text-center">
                <h3 class="mb-2">Enter Bet Amount</h3>
                <input
                  class="dialog-bet-input"
                  type="number"
                  placeholder="10.00"
                  v-model="currentSet.baseBet"
                  disabled
                />
                <div
                  class="rool-info caption orange--text"
                  v-if="currentSet.roll != null"
                >
                  X 10 (Roll {{ currentSet.roll }})
                </div>
                <div
                  class="rool-info caption orange--text"
                  v-if="
                    currentSet.isRambol && this.$route.params.slug == 'lotto4d'
                  "
                >
                  X {{ countPermutations(currentSet) }} (Rambol)
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center text-center ma-0">
              <v-col class="bet-box-cont text-align-center justify-center">
                <v-chip class="bet-box" @click.stop="addBetAmount(10)"
                  >10</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(20)"
                  >20</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(40)"
                  >40</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(80)"
                  >80</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(100)"
                  >100</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(200)"
                  >200</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(300)"
                  >300</v-chip
                >
                <v-chip class="bet-box" @click.stop="addBetAmount(500)"
                  >500</v-chip
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="red darken-1" text @click="clearBetAmount"
              >Clear</v-btn
            >
            <v-btn color="green darken-1" text @click="amountDialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmationSubmit">
        <v-card>
          <v-card-title class="subtitle-1 justify-center"
            >Are you sure with your bets?</v-card-title
          >
          <v-card-actions class="justify-center">
            <v-btn color="red darken-1" text @click="confirmationSubmit = false"
              >Cancel</v-btn
            >
            <v-btn color="green darken-1" text @click="postPlayBet"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import "lodash.permutations";
import "lodash.combinations";

import data from "./../mixins/data";
import helpers from "./../mixins/helpers";
import game from "./../mixins/game";

export default {
  name: "NumberSelector",
  mixins: [data, helpers, game],
  props: {
    game: {
      required: true,
    },
    defaultSets: {
      type: Number,
      default: 6,
    },
    selectionsPerSet: {
      type: Number,
      default: 6,
    },
    minimumNumber: {
      type: Number,
      default: 1,
    },
    maximumNumber: {
      type: Number,
      default: 49,
    },
    isQuickPick: {
      type: Boolean,
      default: false,
    },
    uniqueNumbersOnly: {
      type: Boolean,
      default: true,
    },
    hasTargetRambol: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      amountDialog: false,
      confirmationSubmit: false,
      sets: this.defaultSets,
      selections: this.selectionsPerSet,
      numbersSet: _.range(this.minimumNumber, this.maximumNumber + 1),
      setSelections: [],
      rollOneSelection: [],
      currentSet: {},
      currentCircle: {},
      gameName: this.game.name,
      canRambol: this.game.settings.can_rambol,
      canInputBet: this.game.settings.can_input_amount,
      canInputBetOrCanRambol:
        this.game.settings.can_rambol || this.game.settings.can_input_amount,
      roll: null,
      maxAdvanceBet: this.game.settings.max_advance_draw,
      advanceDrawNum: 1,
      hasSelectedNumbers: false,
      selectedSystemPlay: null,
      canSystemPlay: this.game.settings.has_system_bet,
      systemPlays: [
        {
          name: "S5-R",
          value: "5R",
          selected: false,
          selections: 5,
        },
        {
          name: "S7",
          value: "7",
          selected: false,
          selections: 7,
        },
        {
          name: "S8",
          value: "8",
          selected: false,
          selections: 8,
        },
        {
          name: "S9",
          value: "9",
          selected: false,
          selections: 9,
        },
        {
          name: "S10",
          value: "10",
          selected: false,
          selections: 10,
        },
        {
          name: "S11",
          value: "11",
          selected: false,
          selections: 11,
        },
        {
          name: "S12",
          value: "12",
          selected: false,
          selections: 12,
        },
      ],
    };
  },
  methods: {
    clearBetAmount() {
      this.currentSet.baseBet = this.game.settings.min_bet;
      this.updateBetAmount(this.currentSet);
    },
    addBetAmount(amount) {
      this.currentSet.baseBet += amount;
      this.updateBetAmount(this.currentSet);
    },
    openBetDialog(set) {
      this.currentSet = set;
      this.amountDialog = true;
    },

    getIsRambolLabel(isRambol) {
      if (_.isBoolean(isRambol)) {
        return isRambol ? "R" : "T";
      }
      return "";
    },

    removeBoard(set) {
      if (this.setSelections.length <= 1) {
        return;
      }
      this.setSelections = _.remove(this.setSelections, (item) => {
        return item.number !== set.number;
      });
    },
    toggleIsRambol(set) {
      if (this.isQuickPick) {
        return;
      }

      // toggle RT
      set.isRambol = !set.isRambol;

      if (this.$route.params.slug == "lotto4d") {
        // set to default
        set.roll = null;

        _.each(set.data, (itm) => _.set(itm, "disabled", false));
        if (this.currentSet == set) {
          this.currentCircle.selected = false;
          this.currentCircle = set.data[0];
          this.currentCircle = set.data[0];
          this.currentCircle.selected = true;
        }

        if (set.isRambol) {
          set.bet *= this.countPermutations(set);
        } else {
          set.bet /= this.countPermutations(set);
        }
      }
    },

    getIsRollLabel(roll) {
      if (roll == 1) {
        return "R1";
      }

      if (roll == 4) {
        return "R4";
      }
      return "R1";
    },

    activateRoller(set) {
      this.currentSet = set;
      this.currentSet.isRambol = false;

      let values = [null, 1, 4];
      let index = _.findIndex(values, (value) => {
        return value == set.roll;
      });
      index = index + 1 < values.length ? index + 1 : 0;
      set.roll = values[index];

      if (set.roll == 1) {
        set.data[0].value = null;
        set.data[0].disabled = true;
        set.data[3].disabled = false;
        this.currentCircle.selected = false;
        this.currentCircle = set.data[1];
        this.currentCircle.selected = true;
      }
      if (set.roll == 4) {
        set.data[3].value = null;
        set.data[3].disabled = true;
        set.data[0].disabled = false;

        this.currentCircle.selected = false;
        this.currentCircle = set.data[0];

        this.currentCircle.selected = true;
      }

      if (set.roll == null) {
        _.each(set.data, (itm) => _.set(itm, "disabled", false));
        if (this.currentSet == set) {
          this.currentCircle.selected = false;
          this.currentCircle = set.data[0];
          this.currentCircle = set.data[0];
          this.currentCircle.selected = true;
        }
      }

      this.updateBetAmount(this.currentSet);
    },

    updateBetAmount(set) {
      // set.baseBet += amount;
      let multiplier = 1;

      if (set.isRambol && this.$route.params.slug == "lotto4d") {
        multiplier = this.countPermutations(set);
      } else if (set.roll != null) {
        multiplier = 10;
      } else if (this.selectedSystemPlay != null) {
        multiplier = this.countCombinations(set);
      }

      set.bet = set.baseBet * multiplier;
    },

    countCombinations(set) {
      let combination = _.map(set.data, (item) => {
        return item.value;
      });
      let combinations = _.combinations(combination, this.game.settings.length);
      let uniq_permutations = _.uniqWith(combinations, _.isEqual);
      return uniq_permutations.length > 1 ? uniq_permutations.length : 0;
    },

    countPermutations(set) {
      let combination = _.map(set.data, (item) => {
        return item.value;
      });

      let permutations = _.permutations(combination, this.game.settings.length);
      let uniq_permutations = _.uniqWith(permutations, _.isEqual);
      return uniq_permutations.length;
    },

    isCurrentSet(set) {
      return set.number === this.currentSet.number;
    },
    numToCharacter(num) {
      let s = "",
        t;
      while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = ((num - t) / 26) | 0;
      }
      return s || undefined;
    },

    populateSetSelections() {
      this.setSelections = _.times(this.defaultSets, (index) => {
        return {
          number: index + 1,
          data: _.times(this.selections, () => {
            return {
              selected: false,
              value: null,
              disabled: false,
            };
          }),
          roll: null,
          isRambol: false,
          bet: 0,
          baseBet: 0,
        };
      });
    },
    focusFirstSet() {
      this.currentSet = this.setSelections[0];
      this.currentCircle = this.setSelections[0].data[0];
      this.currentCircle.selected = true;
    },
    setCurrentSet(set, selectedNumber) {
      if (this.isQuickPick || selectedNumber.disabled) {
        return;
      } else {
        this.expand = true;
      }
      this.currentSet = set;
      this.currentCircle.selected = false;
      this.currentCircle = selectedNumber;
      this.currentCircle.selected = true;
    },
    selectNumber(number) {
      if (this.currentCircle.disabled) {
        return;
      }

      // if same number is selected for current circle clear the value
      if (this.currentCircle.value === number) {
        return (this.currentCircle.value = null);
      }

      // dont let user select the number if current number is selected on the other circle for the current set
      if (this.isSelectedInCurrentSet(number) && this.uniqueNumbersOnly) {
        return;
      }

      if (this.currentSet.bet == 0) {
        this.currentSet.bet = this.game.settings.min_bet;
        this.currentSet.baseBet = this.currentSet.bet;
      }

      this.currentCircle.value = number;

      this.updateBetAmount(this.currentSet);

      this.goToNextCircle();

      this.$emit("number-selected", this.setSelections);

      this.hasSelectedNumbers = true;
    },

    goToPreviousCircle() {
      let currentSetIndex = _.findIndex(this.setSelections, this.currentSet);
      let currentSetCircleIndex = _.findIndex(
        this.currentSet.data,
        this.currentCircle
      );
      let currentSet = this.currentSet;
      let previousSet = this.setSelections[currentSetIndex - 1];
      let previousSetCircle = this.currentSet.data[currentSetCircleIndex - 1];

      // if there is still prev step and is the last circle
      // go to the prev step and set last circle as current circle
      if (previousSet !== undefined && previousSetCircle === undefined) {
        this.currentSet = previousSet;

        this.currentCircle.selected = false;
        this.currentCircle.value = null;
        this.currentCircle = _.last(previousSet.data);
        this.currentCircle.selected = true;
      }

      // if there is still prev circle in current step set it as prev circle
      if (previousSetCircle !== undefined) {
        this.currentCircle.selected = false;
        this.currentCircle.value = null;
        this.currentCircle = previousSetCircle;
        this.currentCircle.selected = true;
      }

      if (this.currentCircle.disabled) {
        this.goToPreviousCircle();
      }

      if (previousSetCircle === undefined && previousSet === undefined) {
        this.currentCircle.value = null;
        this.hasSelectedNumbers = false;
      }

      this.updateBetAmount(currentSet);
    },

    selectSystemPlay(systemPlay) {
      if (systemPlay.selected) {
        this.selectedSystemPlay = null;
        this.selections = this.defaultSets;
        systemPlay.selected = false;
      } else {
        if (this.selectedSystemPlay) {
          this.selectedSystemPlay.selected = false;
        }

        this.selectedSystemPlay = systemPlay;
        this.selectedSystemPlay.selected = true;
        this.selections = systemPlay.selections;
      }

      this.populateSetSelections();
      this.focusFirstSet();
    },

    // hasSytemPlay() {
    //   if (this.selectedSystemPlay === null) {
    //     return false;
    //   }
    //   return this.selectedSystemPlay;
    // },
    getsystemActiveClass() {
      return;
    },

    goToNextCircle() {
      let currentSetIndex = _.findIndex(this.setSelections, this.currentSet);
      let currentSetCircleIndex = _.findIndex(
        this.currentSet.data,
        this.currentCircle
      );
      let nextSet = this.setSelections[currentSetIndex + 1];
      let nextSetCircle = this.currentSet.data[currentSetCircleIndex + 1];

      // if there is still next step and is the last circle
      // go to the next step and set first circle as current circle
      if (nextSet !== undefined && nextSetCircle === undefined) {
        this.currentSet = nextSet;

        this.currentCircle.selected = false;
        this.currentCircle = nextSet.data[0];
        this.currentCircle.selected = true;
      }

      // if there is still next circle in current step set it as next circle
      if (nextSetCircle !== undefined) {
        this.currentCircle.selected = false;
        this.currentCircle = nextSetCircle;
        this.currentCircle.selected = true;
      }

      if (this.currentCircle.disabled) {
        this.goToNextCircle();
      }

      if (nextSetCircle === undefined && nextSet === undefined) {
        this.addSet();
        this.goToNextCircle();
      }
    },
    isSelectedInCurrentSet(number) {
      return _.includes(this.getCurrentSetNumbers, number);
    },
    quickPick() {
      this.setSelections = _.map(this.setSelections, (item) => {
        return {
          number: item.number,
          data: _.map(this.getRandomSet(), (number) => {
            return {
              selected: false,
              value: number,
              disabled: false,
            };
          }),
          roll: null,
          isRambol: false,
          bet: this.game.settings.min_bet,
          baseBet: this.game.settings.min_bet,
        };
      });
    },
    removeLastSet() {
      if (this.setSelections.length <= 1) {
        return;
      }
      this.setSelections = _.remove(this.setSelections, (el, i) => {
        return i !== this.setSelections.length - 1;
      });
    },

    addSet() {
      if (this.isQuickPick) {
        this.setSelections.push({
          number: _.last(this.setSelections).number + 1,
          data: _.map(this.getRandomSet(), (number) => {
            return {
              selected: false,
              value: number,
              disabled: false,
            };
          }),
          roll: null,
          isRambol: false,
          bet: this.game.settings.min_bet,
          baseBet: this.game.settings.min_bet,
        });
      } else {
        this.setSelections.push({
          number: _.last(this.setSelections).number + 1,
          data: _.times(this.selections, () => {
            return {
              selected: false,
              value: null,
              disabled: false,
            };
          }),
          roll: null,
          isRambol: false,
          bet: 0,
          baseBet: 0,
        });
      }
    },

    confirmOnSubmit() {
      if (!this.hasSelectedNumbers) {
        this.confirmationSubmit = false;
        this.postPlayBet();
      } else {
        this.confirmationSubmit = true;
      }
    },
    getRandomSet() {
      if (!this.uniqueNumbersOnly) {
        return _.times(this.selections, () => _.sample(this.numbersSet));
      }

      return _.sampleSize(this.numbersSet, this.selections);
    },
    async postPlayBet() {
      this.$emit("submit-play", true);
      let data = this.getFormattedSelections;

      this.confirmationSubmit = false;

      await this.postData(`/${this.$route.params.slug}/bets`, data, (res) => {
        localStorage.setItem("bet-success", JSON.stringify(res));
        this.$emit("submit-play", true);
        this.$router.push({ path: this.$route.path + "/success" });
      });

      this.$nextTick(function() {
        this.$emit("submit-play", false);
      });
    },
  },

  watch: {},
  computed: {
    isSystemPlay() {
      return (
        _.filter(this.systemPlays, (systemPlay) => {
          systemPlay.selected;
        }).length > 0
      );
    },
    //custom column on 4d Game
    customCol4D() {
      return this.$route.params.slug == "lotto4d" ? 6 : 4;
    },
    //custom column on Boards
    customBetsColumn() {
      if (this.$route.params.slug == "lotto4d") {
        return this.canInputBetOrCanRambol ? 6 : 8;
      }
      return this.canInputBetOrCanRambol ? 8 : 12;
    },
    getFormattedSelections() {
      return {
        boards: _.map(this.setWithSelectedNumbers, (set) => {
          var combination = _.map(set.data, (item) => item.value);
          if (set.roll === 1) {
            combination = _.drop(combination);
          } else if (set.roll === 4) {
            combination = _.dropRight(combination);
          }

          return {
            combination: combination,
            roll: set.roll,
            is_rambol: set.isRambol,
            amount: set.bet,
          };
        }),

        advance_draw: this.advanceDrawNum,
        system_bet: this.selectedSystemPlay
          ? this.selectedSystemPlay.value
          : null,
      };
    },
    getCurrentSetNumbers() {
      return _.map(this.currentSet.data, (item) => {
        return item.value;
      });
    },
    setWithSelectedNumbers() {
      return _.filter(this.setSelections, (item) => {
        return _.filter(item.data, (number) => {
          return number.value !== null;
        }).length;
      });
    },
    numberOfBets() {
      return this.setWithSelectedNumbers.length;
    },
    getTaxLabel() {
      return this.game.settings.tax * 100;
    },
    getTotalTax() {
      return this.getTotalBets * this.game.settings.tax * this.advanceDrawNum;
    },
    getSubTotal() {
      return this.getTotalBets * this.advanceDrawNum;
    },
    total() {
      return this.getTotalBets * this.advanceDrawNum + this.getTotalTax;
    },
    getTotalBets() {
      return _.sumBy(this.setSelections, "bet");
    },
    selectionsCount() {
      return this.setSelections.length;
    },
  },
  mounted() {
    this.populateSetSelections();

    if (this.isQuickPick) {
      this.quickPick();
    } else {
      this.focusFirstSet();
    }

    if (!this.isQuickPick) {
      this.expand = true;
    }
  },
};
</script>
<style scoped>
.num-cont {
  background: #f9f9f9;
  border-top: 1px solid #f2f2f2;
}
.num-cont.active {
  background: #fff8bd;
}
.num {
  margin-right: 2px;
}
.lotto-circle {
  background: #fff;
  border: 1px solid #c6daea;
}
.current-selected {
  border: 1px dashed #e04646;
  box-shadow: 0 -4px 9px 0 rgba(0, 0, 0, 0.08);
}
.done {
  background: #ff4f4f;
  color: #fff;
  border: 1px solid #ff4f4f;
}

span.bet-options button,
.bet-btn-cont button {
  height: 30px !important;
  min-width: 30px !important;
}

.bet-input {
  padding: 3px 10px;
  background: #ddf0ff;
  min-width: 65px;
  border-radius: 5px;
}
.textme {
  position: fixed;
  width: 100%;
  max-width: 768px;
  bottom: 70px;
}
.number-selector {
  width: 11%;
  text-align: center;
  padding: 10px;
}
.toggler {
  padding: 0 20px;
}

.v-chip-group .v-chip {
  margin: 3px 1px 1px 2px !important;
  padding: 0;
  width: 10% !important;
  align-items: center;
  justify-content: center;
}
.selected-number {
  text-decoration: line-through;
  background: #e04646 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.box-cont {
  background: #fff;
  box-shadow: 0 -4px 9px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
}
.my-floater {
  position: absolute;
  right: 10px;
  top: -50px;
}
.bet-box {
  width: 20%;
  justify-content: center;
  margin: 4px;
}
.dialog-bet-input {
  width: 20%;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
}
button.btn-to {
  min-width: 35px !important;
}

.lotto-circle.disabled {
  background: #d2d2d2;
  border: 1px solid #ababab;
}
.rool-info {
  margin-left: 10px;
}
.amount-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.num-column {
  overflow-x: scroll;
  border-right: 1px solid #adadad36;
}
.system-group-btn {
  border: 0 !important;
}
.system-active {
  background-color: #ff6a00 !important;
  border: 0;
  color: #fff !important;
}
</style>