<template>
  <v-container fluid v-if="user" class="account-container">
    <v-row class="account-master-head"></v-row>
    <v-row class="pcso-account-header">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row class="profile-cont mx-0 pa-2">
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-badge color="warning" dot overlap>
                  <v-avatar size="55" class="mb-2 elevation-2">
                    <img src="/imgs/rep.jpeg" alt />
                  </v-avatar>
                </v-badge>
              </v-col>
              <v-col cols="9" class="pl-0">
                <div class="float-right">
                  <v-btn small icon color="#ffffff6b">
                    <v-icon size="20">mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </div>
                <div>{{ user.name }}</div>
                <p class="body-2 mb-0">{{ user.mobile }}</p>
                <v-btn rounded outlined x-small color="orange lighten-3">Verify Now</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col class="pt-0">
                <v-card class="credits-cont mx-auto mb-3" flat dark>
                  <v-card-text class="pa-3">
                    <div class="float-right">Account Credits</div>
                    <h2
                      class="credit-bal font-weight-bold mt-4"
                    >&#8369;{{ formatMoney(user.credits) }}</h2>

                    <div class="mt-4">
                      <v-btn
                        rounded
                        small
                        depressed
                        color="green darken-1"
                        router
                        to="/add-credits"
                      >
                        <v-icon size="18" dark>mdi-plus</v-icon>
                        <p class="caption mb-0">Add Credits</p>
                      </v-btn>
                      <v-btn
                        class="ml-2"
                        rounded
                        small
                        depressed
                        color="orange darken-3"
                        router
                        to="/withdraw"
                      >
                        <p class="caption mb-0">Cashout</p>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <div class style="margin-top:-35px;">
      <v-row>
        <v-col cols="4">
          <v-card class="custom-tab">
            <div
              class="text-center px-2 py-4"
              :class="{ active: tab === 0 }"
              @click="setTab(0)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
              <p class="mb-0 caption">Transactions</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="custom-tab">
            <div
              class="text-center px-2 py-4"
              :class="{ active: tab === 1 }"
              @click="setTab(1)"
            >
              <v-icon>mdi-ticket</v-icon>
              <p class="mb-0 caption">My Tickets</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="custom-tab">
            <div
              class="text-center px-2 py-4"
              :class="{ active: tab === 2 }"
              @click="setTab(2)"
            >
              <v-icon>mdi-account-convert</v-icon>
              <p class="mb-0 caption">Settings</p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <v-tabs-items
            v-model="tab"
            class="pcso-account-details"
            style="margin-top:-30px; background:#ffffff00"
          >
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12 main-tab-cont ">
                    <v-subheader class="text-uppercase font-weight-medium pa-0">Transaction History</v-subheader>
                    <v-card flat color="grey lighten-5">
                      <transactions />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <tickets-list v-if="initTickets" />
            </v-tab-item>
            <v-tab-item>
              <div class="pa-4 mt-6 mb-12">
                <div class="row pcso-account-details-header">
                  <div class="col-12">
                    <strong>Personal Details</strong>
                  </div>
                </div>
                <v-divider class="mb-4" />
                <v-text-field v-model="user.name" label="Name" type="text" readonly></v-text-field>
                <v-text-field v-model="user.mobile" label="Mobile" type="text" readonly></v-text-field>
                <v-text-field value="test@email.com" label="Email" type="text" readonly></v-text-field>
                <v-text-field value="Test Address" label="Address" type="text" readonly></v-text-field>
                <v-btn class="elevation-0 mb-3" rounded block color="primary">Edit Account</v-btn>
                <v-btn class="elevation-0" rounded block color="error" @click.stop="logout()">Logout</v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import helpers from "./../mixins/helpers";
import TicketsList from "./../components/TicketsList";
import Transactions from "./../views/wallet/Transactions";

export default {
  mixins: [mixin, helpers],
  components: {
    TicketsList,
    Transactions,
  },
  data() {
    return {
      tab: this.$route.params.tab || 0,
      initTickets: false,
    };
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.initTickets = true;
      }
    },
  },
  mounted() {
    this.getUserProfile();
    setTimeout(() => {
      if (this.$route.params.tab) this.tab = parseInt(this.$route.params.tab);
    }, 100);

    this.$nextTick(() => {
      if (!this.games.length) this.getGames();
    });
  },
};
</script>

<style lang="sass" scoped>
.account-container
  background-color: #fff
  min-height: 100vh
.pcso-account-header
  position: relative
  margin-top: -12px
  color: #fff
  .profile-cont
    background: #ffffff08
    border: 1px solid #ffffff05
    border-radius: 10px

.credit-bal
  font: size 1.3rem
  font-weight: 600
  color: #fff
.credits-cont
  background-image: linear-gradient(90deg, #f37d37, #e6a946)

.account-master-head
  background-image: linear-gradient(21deg, #4165b7, #602d9a)
  min-height: 185px
  width: 100%
  position: absolute
  margin-top: -12px
.custom-tab .active
  background: #3d5a9c
  color: #fff
  .v-icon
    color: #fff
</style>
