<template>
  <v-container class="pa-0 pb-8 ma-0 bg-white" fluid>
    <page-header title="Withdraw" bg="or.png" back-button="account">
      <strong 
        class="d-block white--text text-center d-block mt-1" 
        style="text-shadow: 1px 1px 1px rgba(0,0,0,.4)"
        v-html="'Credits: ' + formatMoney(user.credits)">
      </strong>
    </page-header>
    <v-sheet color="#f2f2f2" class="ma-3 mt-4 pa-3 pb-4 text-center outlet-form">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mb-5 wallet-form"
        @submit="submit()"
      >
        <v-text-field
          type="number"
          placeholder="0"
          v-model="withdraw.amount"
          label="Amount To Withdraw"
          :loading="busy"
          :rules="[rules.required, rules.amountRule, withdrawAmountRule, creditsRule]"
          :error-messages="error && error.amount ? error.amount[0] : ''"
          required
        />
        <v-select
          :items="outlets"
          :label="outletLabel"
          v-model="withdraw.outlet"
          item-text="name"
          item-value="id"
          :loading="busy"
          :rules="[rules.required]"
          @change="select(withdraw.outlet)"
        />
        <v-text-field
          v-if="withdraw.outlet === 'gcash'"
          type="number"
          v-model="withdraw.account_number"
          label="GCash Number"
          :loading="busy"
          :rules="[rules.required, rules.phone]"
          :error-messages="error && error.account_number ? error.account_number[0] : ''"
        />
        <v-text-field
          v-if="withdraw.outlet === 'mlhuillier'"
          type="text"
          v-model="withdraw.account_number"
          label="Receiver's Name"
          :loading="busy"
          :rules="[rules.required]"
          :error-messages="error && error.account_number ? error.account_number[0] : ''"
        />
        <template v-if="withdraw.outlet === 'bank'">
          <v-select
            :items="banks"
            label="Bank Name"
            v-model="withdraw.bank_name"
            item-text="name"
            item-value="id"
            :loading="busy"
            :rules="[rules.required]"
          />
          <v-text-field
            type="text"
            v-model="withdraw.account_name"
            label="Account Name"
            :loading="busy"
            :rules="[rules.required]"
            :error-messages="error && error.account_name ? error.account_name[0] : ''"
          />
          <v-text-field
            type="number"
            v-model="withdraw.account_number"
            label="Account Number"
            :loading="busy"
            :rules="[rules.required]"
            :error-messages="error && error.account_name ? error.account_name[0] : ''"
          />
          <v-text-field
            type="number"
            v-if="withdraw.bank_name === 'landbank'"
            v-model="withdraw.card_number"
            label="Card Number"
            :loading="busy"
            :rules="[rules.required]"
            :error-messages="error && error.card_name ? error.card_name[0] : ''"
          />
        </template>
        <v-text-field
          type="number"
          v-model="withdraw.contact_number"
          :loading="busy"
          :rules="[rules.phone]"
          :error-messages="error && error.contact_number ? error.contact_number[0] : ''"
          label="PH Contact Number (Optional)"
          hint="This will be used to inform you of the status of your withdrawal. 
          By default, this is the same as your account's mobile number."
          persistent-hint
        />
      </v-form>
      <v-sheet light class="notes mb-5 pa-3" v-if="withdraw.outlet !== 'si'">
        Withdrawals are processed at a minimum of 1 banking day.
        Applicable fees and charges will be shouldered by the user
      </v-sheet>
      <v-btn rounded class="elevation-0" block color="primary" :loading="busy" @click="submit()">SUBMIT</v-btn>
    </v-sheet>
    <withdraw-history />
  </v-container>
</template>

<script>
import mixin from "./../../mixins";
import rules from "./../../mixins/rules";
import helpers from "./../../mixins/helpers";
import WithdrawHistory from "./sections/WithdrawHistory";
import PageHeader from "./../../sections/PageHeader";

export default {
  mixins: [mixin, rules, helpers],
  components: {
    WithdrawHistory,
    PageHeader
  },
  data: () => ({
    mode: "withdrawals",
    outletLabel: "Outlet",
    selected: null,
    outlet: null,
    withdraw: {
      amount: null,
      outlet: null,
      receiver_name: null,
      receiver_number: null,
      account_name: null,
      account_number: null,
      bank_name: "bpi",
      contact_number: localStorage.getItem("mobile")
    },
    siCredits: 0.00,
    valid: true
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    outletsStore() {
      return this.$store.state.outlets.withdrawals;
    },
    outlets() {
      if (this.outletsStore) {
        return this.outletsStore.outlets.filter(outlet => {
          return outlet.visible;
        });
      }
      return [];
    },
    banks() {
      let selection = [];
      let obj = this.outletsStore.banks;
      Object.keys(obj).forEach(key => {
        selection.push({ id: key, name: obj[key] });
      });
      return selection;
    },
    creditsRule() {
      return (
        this.withdraw.amount <= parseInt(this.user.credits) ||
        `You only have ${this.formatMoney(this.user.credits)} credits`
      );
    },
    withdrawAmountRule() {
      if (this.selected) {
        return (
          this.withdraw.amount >= parseInt(this.selected.min) ||
          `Minimum amount is ${this.formatMoney(this.selected.min)}`
        );
      }
      return true;
    }
  },
  methods: {
    select(selected) {
      this.selected = this.outlets.find(outlet => {
        return selected === outlet.id;
      });
    },
    reset() {
      this.$nextTick(() => {
        this.$refs.form.reset()
        this.withdraw.amount = 0
        this.withdraw.contact_number = localStorage.getItem("mobile")
      });
    },
    getCredits() {
      this.getSICredits(this.user.mobile, response => {
        this.siCredits = response
        this.outletLabel = `Outlet (SI Credits: ${this.siCredits})`
      })
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        let data = this.clean(this.withdraw);
        if (data.contact_number === "") data.contact_number = null;
        window.axios
          .post(`${window.SETTINGS.API_URL}/lotto/wallet/withdrawals`, data)
          .then(response => {
            this.$store.dispatch("prompt", { message: response.data.message });
            this.reset();
            this.$store.dispatch('appEvent', 'withdrawal-submitted')
          })
          .catch(error => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    }
  },
  watch: {
    'withdraw.outlet' () {
      if (this.withdraw.outlet === 'si') {
        this.getCredits()
      } else {
        this.outletLabel = "Outlet"
      }
    }
  },
  mounted() {
    this.getOutlets(this.mode);
  }
};
</script>