<template>
  <v-container class="pa-0 ma-0 bg-white" fluid>
    <page-header title="Cash Ins" bg="or.png" back-button="add-credits" />
    <div v-if="hasList">
      <div class="mb-3">
        <v-layout
          class="list-history px-3 py-3"
          :class="`${deposit.status}-status`"
          v-for="(deposit, index) in lists"
          :key="index"
        >
          <v-flex class="text-left">
            {{ formatMoney(parseFloat(deposit.amount)) }}
            <div class="with-info dep-info">{{ deposit.outlet_obj ? deposit.outlet_obj.name : deposit.outlet }}</div>
          </v-flex>
          <v-flex v-if="deposit.outlet === 'mltpy' && deposit.multipay_ref" xs4 class="text-left">
            <div class="with-info dep-info">
              Ref: {{ deposit.multipay_ref }}
            </div>
          </v-flex>
          <v-flex v-else xs4 class="text-left">
            <div>
              {{ deposit.reference_number }}
            </div>
            <div class="with-info dep-info">
              Frm: {{ deposit.sender }}
            </div>
            <div class="with-info dep-info">
              To: {{ deposit.receiver }}
            </div>
            <div class="with-info dep-info" v-if="deposit.status === 'invalid'">
              {{ deposit.admin_notes }}
            </div>
          </v-flex>
          <v-flex xs4 class="text-right">
            {{ deposit.date_label }}
            <div class="with-info dep-info">
              {{ deposit.time_label }}
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div v-else-if="lists < 1" class="text-center text-lose mt-8 pb-8">
      <small>{{ loading ? 'Loading...' : 'No cash-ins were made yet' }}</small>
    </div>
    <div v-if="hasMore" class="my-3 text-center">
      <v-btn text small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
    </div>
  </v-container>
</template>

<script>
import mixins from "./../../mixins";
import list from "./../../mixins/list";
import helpers from "./../../mixins/helpers";
import PageHeader from "./../../sections/PageHeader";

export default {
  mixins: [mixins, list, helpers],
  components: {
    PageHeader
  },
  data: () => ({
    dataUrl: "/lotto/wallet/deposits",
  }),
};
</script>

<style lang="sass">
.valid-status
  color: #fff
  background-color: #2E7D32 !important
  border-bottom: 1px solid #1B5E20 !important

.invalid-status
  color: #fff
  background-color: #C62828 !important
  border-bottom: 1px solid #B71C1C !important

.pending-status
  border-bottom: 1px solid #f2f2f2 !important
</style>