<template>
  <v-container dark class="fill-height" fluid>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="main-form"
      @submit="submit()"
    >
      <div class="text-center">
        <img src="/imgs/logo.png" class="login-logo mb-8" />
      </div>
      <v-alert
        dark
        :color="'red'"
        class="my-4 mb-6"
        v-if="error && error.mobile"
        ><small>{{ error.mobile[0] }}</small></v-alert
      >
      <v-text-field
        dark
        v-model="credentials.mobile"
        label="Mobile Number"
        type="number"
        pattern="\d*"
        :rules="[rules.required, rules.phone]"
        :error="!!error"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.password"
        label="Password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        :rules="[rules.required]"
        :error="!!error"
        @click:append="show = !show"
        @keypress.enter="submit()"
        required
      ></v-text-field>
      <div class="text-center mt-4">
        <v-btn
          color="#fcd016"
          class="mb-0 elevation-0"
          block
          rounded
          @click="submit()"
          :loading="busy"
        >
          Login
        </v-btn>
        <br />
        <!-- <p class="auth-note">
          No account yet?
        </p> -->
        <v-btn
          dark
          text
          rounded
          class="mb-2"
          @click.stop="$router.push({ name: 'register' })"
        >
          Register
        </v-btn>
        <br />
        <v-btn
          color="#888"
          text
          rounded
          @click.stop="$router.push({ name: 'forgot-password' })"
        >
          Forgot Password
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid: true,
    show: false,
    credentials: {
      mobile: localStorage.getItem("mobile"),
      password: null,
      guid: null,
    },
  }),
  methods: {
    submit() {
      if (this.urlParams)
        this.credentials.guid = this.urlParams.get("guid") || null;
      if (!this.credentials.guid) this.credentials.guid = window.guid;
      if (this.$refs.form.validate()) this.login(this.credentials);
    },
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.axios.defaults.headers.common["Authorization"] = null;
  },
};
</script>