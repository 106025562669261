import Vue from 'vue'
import Router from 'vue-router'

// user
import Play from './views/Play.vue'
import Game from './views/Game.vue'
import GamePlay from './views/GamePlay.vue'
import Results from './views/Results.vue'
import Account from './views/Account.vue'
import Help from './views/Help.vue'
import Success from './views/Success.vue'
import Deposit from './views/wallet/Deposit.vue'
import Transactions from './views/wallet/Transactions.vue'
import Withdraw from './views/wallet/Withdraw.vue'
import CashIns from './views/wallet/CashIns.vue'
import Settings from './views/Settings.vue'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import Verify from './views/Verify.vue'
import NotFound from './views/NotFound.vue'

Vue.use(Router)

export default new Router({
    // mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'play',
            component: Play
        },
        {
            path: '/game/:slug',
            name: 'game',
            component: Game
        },
        {
            path: '/game/:slug/:pick',
            name: 'game-play',
            component: GamePlay
        },
        {
            path: '/game/:slug/:pick/success',
            name: 'success',
            component: Success
        },
        {
            path: '/results',
            name: 'results',
            component: Results
        },
        {
            path: '/account',
            name: 'account',
            component: Account
        },
        {
            path: '/account/:tab',
            name: 'account-tickets',
            component: Account
        },
        {
            path: '/add-credits',
            name: 'add-credits',
            component: Deposit
        },
        {
            path: '/withdraw',
            name: 'withdraw',
            component: Withdraw
        },
        {
            path: '/cash-ins',
            name: 'cash-ins',
            component: CashIns
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: Transactions
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/login/:status',
            name: 'registered',
            component: Login
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword
        },
        {
            path: '/forgot-password/:status',
            name: 'new-password',
            component: ForgotPassword
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
            path: '/verify',
            name: 'verify',
            component: Verify
        },
        {
            path: '/help',
            name: 'help',
            component: Help
        },
        {
            path: '*',
            component: NotFound
        }
    ]
})
