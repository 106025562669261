<template>
  <v-container class="fill-height" fluid>
    <v-form
      v-if="!submitted"
      ref="form"
      v-model="valid1"
      lazy-validation
      class="main-form"
      @submit="requestReset()"
    >
      <div class="text-center mb-4">
        <img src="/imgs/logo.png" class="reg-logo mb-0" />
        <h4>Forgot Password</h4>
        <p
          class="mt-4 auth-note"
        >Reset your account's password by providing the mobile number associated with your account.</p>
      </div>
      <v-text-field
        dark
        v-model="request.mobile"
        label="Mobile Number"
        type="number"
        :rules="[rules.required, rules.phone]"
        :error-messages="error && error.mobile ? error.mobile[0] : ''"
        :loading="busy"
        @keypress.enter="requestReset()"
        required
      ></v-text-field>
      <div class="text-center mt-4">
        <v-btn
          color="#fcd016"
          class="mb-5 elevation-0"
          rounded
          @click.stop="requestReset()"
          :loading="busy"
          block
        >Submit</v-btn>
        <v-btn dark text rounded @click.stop="$router.push({ name: 'login' })">Back to login</v-btn>
      </div>
    </v-form>
    <v-form
      v-else
      ref="resetForm"
      v-model="valid2"
      lazy-validation
      class="main-form"
      @submit="resetPassword()"
    >
      <div class="text-center mb-4">
        <img src="/imgs/logo.png" class="reg-logo mb-0" />
        <h4>Reset Password</h4>
        <p class="mt-4 auth-note">
          We sent a One Time Pin to
          <strong>{{ credentials.mobile }}</strong>. Please use the pin to reset your password.
        </p>
      </div>
      <v-text-field
        dark
        v-model="credentials.pin"
        label="One Time Pin"
        type="number"
        :rules="[rules.required]"
        :error-messages="error && error.pin ? error.pin[0] : ''"
        :loading="busy"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.password"
        label="Password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        :loading="busy"
        :rules="[rules.required, rules.password]"
        :error-messages="error && error.password ? error.password[0] : ''"
        required
      ></v-text-field>
      <v-text-field
        dark
        v-model="credentials.password_confirmation"
        label="Confirm Password"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2"
        :loading="busy"
        :rules="[rules.required, rules.password, passwordConfirmationRule]"
        :error-messages="error && error.password_confirmation ? error.password_confirmation[0] : ''"
        @keypress.enter="resetPassword()"
        required
      ></v-text-field>
      <div class="text-center mt-4">
        <v-btn
          color="#fcd016"
          class="mb-5 elevation-0"
          rounded
          @click.stop="resetPassword()"
          :loading="busy"
          block
        >RESET</v-btn>
        <v-btn dark text rounded @click.stop="$router.push({ name: 'login' })">Back to login</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import auth from "./../mixins/auth";
import rules from "./../mixins/rules";

export default {
  mixins: [mixin, auth, rules],
  data: () => ({
    valid1: true,
    valid2: true,
    submitted: false,
    show1: false,
    show2: false,
    request: {
      mobile: null
    },
    credentials: {
      mobile: null,
      pin: null,
      password: null,
      password_confirmation: null
    }
  }),
  computed: {
    passwordConfirmationRule() {
      return (
        this.credentials.password === this.credentials.password_confirmation ||
        "Password must match"
      );
    }
  },
  methods: {
    requestReset() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        window.axios
          .post(`${window.SETTINGS.API_URL}/auth/reset/request`, this.request)
          .then(() => {
            this.submitted = true;
          })
          .catch(error => {
            if (error && error.response)
              this.error = error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
    resetPassword() {
      if (this.$refs.resetForm.validate()) {
        this.busy = true;
        this.credentials.mobile = this.request.mobile;
        window.axios
          .post(`${window.SETTINGS.API_URL}/auth/reset`, this.credentials)
          .then(() => {
            this.$store.dispatch("snackbar", {
              message: "Password updated successfully"
            });
            this.$router.push({ name: "play" });
          })
          .catch(error => {
            if (error && error.response)
              this.error =
                error.response.data.errors || error.response.data.message;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    }
  }
};
</script>