<template>
  <v-row class="ma-2 ticket-row" :class="{ done: drawHasResult }">
    <v-col cols="8" class="pr-0 ticket-col" @click="setCurrentTicket">
      <p class="mb-2 body-2 font-weight-medium" v-html="title"></p>
      <combinations
        class="mb-1"
        :numbers="numbers"
        :roll="roll"
        :is_rambol="isRambol"
      />
      <p class="mb-0 caption" v-html="valid"></p>
      <p class="mb-0 caption" v-html="submitAt"></p>
      <v-btn
        v-if="numberOfDraws"
        depressed
        rounded
        outlined
        x-small
        color="warning"
      >
        <span v-html="numberOfDraws"></span>
        <span
          class="advance-wins"
          v-if="advanceWin > 0"
          v-text="advanceWinText"
        ></span>
      </v-btn>
    </v-col>
    <v-col
      cols="4"
      class="ticket-col ticket-right d-flex flex-column justify-center align-center "
      style="position:relative"
      :class="{ winner: status }"
    >
      <div class="break-circle top "></div>
      <div class="break-circle bottom "></div>

      <p class="mb-0 body-2 font-weight-medium" v-html="betamount"></p>
      <v-avatar class tile size="35">
        <img :src="'/imgs/' + slug + '.png'" />
      </v-avatar>

      <div>
        <v-btn v-if="status" depressed rounded x-small color="success"
          >WINNER</v-btn
        >
        <div class="pcso-ref caption" v-html="ticketref"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Combinations from "./../components/Combinations";

export default {
  props: [
    "title",
    "ticketref",
    "submitAt",
    "betamount",
    "winner",
    "valid",
    "drawHasResult",
    "numberOfDraws",
    "advanceWin",
    "numbers",
    "slug",
    "roll",
    "isRambol",
    "status",
    "advance_bets",
  ],
  components: {
    Combinations,
  },
  data: () => ({
    currentAdvanceBet: [],
    ticketData: {},
  }),
  methods: {
    setCurrentTicket() {
      this.ticketData.advanceBets = this.advance_bets;
      this.ticketData.slug = this.slug;
      this.ticketData.title = this.title;

      this.$emit("set-current-ticket", this.ticketData);
    },
  },
  computed: {
    advanceWinText() {
      return this.advanceWin > 1
        ? this.advanceWin + " WINS"
        : this.advanceWin + " WIN";
    },
  },
};
</script>

<style lang="sass" scoped>
.pcso-draws-list
  border-bottom: 0 !important
.pcso-result-prize
  color: #444
  font-size: .8em
.advance-wins
  background: #4caf50
  color: #FFF
  padding: 1px 6px
  border-radius: 12px
.pcso-draws-list.done
  opacity: 0.6
  background: #f1f1f1
.pcso-draws-list.win
  background: #55c15a3d

.break-circle
  position: absolute
  width: 20px
  height: 20px
  background: #fff
  z-index: 2
  border-radius: 20px
.top
  left:-11px
  top: -11px
.bottom
  left:-11px
  bottom: -11px
.ticket-head
  background-image: linear-gradient(90deg, #306b9e, #5b90b7)
  border-radius: 5px 5px 0 0
  color: #fff

.winner
  background-color: #cbefcc !important

.done
  opacity: 0.7
  filter: grayscale(100%)

.advance-wins
  background: #ffe2b6
  color: #3b9e5a
  border-radius: 11px
  padding: 1px 5px
  font-size: 9px

.ticket-row
  background: #f3f3f3
  border-radius: 10px
.ticket-col
  border-radius: 10px
.ticket-right
  border-left: 2px dashed #fff
</style>