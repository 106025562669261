<template>
  <v-container class="pcso-game-header py-0">
    <v-row>
      <v-col class="d-flex justify-space-between py-2">
        <div class="go-back-arrow ">
          <v-btn icon @click.stop="goBack()">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div>
          <span>
            <v-btn icon @click="gameHelp()">
              <v-icon color="white"> mdi-help-circle</v-icon>
            </v-btn>
          </span>
          <span>
            <v-btn icon @click.stop="refresh()">
              <v-icon color="white">mdi-cached</v-icon>
            </v-btn>
          </span>
          <span></span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="game" class="d-flex justify-center mb-6">
      <v-col cols="11" class="price-bg py-0">
        <v-row class="align-center justify-center">
          <v-col cols="3" class="">
            <div class="pcso-game-logo">
              <img class="pa-2" :src="'./imgs/' + slug + '.png'" />
            </div>
          </v-col>
          <v-col v-if="hasJackpot" cols="9" class="py-2">
            <v-card flat dark>
              <div class="float-right text-right">
                <p class="mb-0 body-2 font-italic">Jackpot Price</p>
                <h3 style="color: #ffa85c">
                  &#8369;{{ formatMoney(jackpotAmount) }}
                </h3>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="draw-list-head">
      <v-col class="d-flex justify-space-between align-center px-4 py-2">
        <p class="caption mb-0 white--text">
          <v-icon size="20" color="white">mdi-calendar-text</v-icon>
          {{ drawDay + ", " + drawDate }}
        </p>
        <div class="">
          <v-btn depressed text dark rounded x-small class="cap">
            <div class="d-flex align-center">
              <v-avatar color="success" size="8" class="blink"></v-avatar>
              <p class="caption mb-0 ml-1">{{ drawLabel }} DRAW</p>
            </div>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center pa-4" style="background: #f9f9f9">
      <v-col class="d-flex justify-center py-0">
        <v-btn-toggle rounded borderless class="switch-btn-group">
          <v-btn
            small
            rounded
            class="switch-btn"
            @click.stop="$router.push({ path: `/game/${slug}/manual-pick` })"
            :color="
              !isQuickPick ? 'indigo darken-2 white--text' : 'v-btn--text'
            "
            >Manual Pick</v-btn
          >
          <v-btn
            small
            rounded
            class="switch-btn"
            @click.stop="$router.push({ path: `/game/${slug}/quick-pick` })"
            :color="
              isQuickPick ? 'orange lighten-1 white--text' : 'v-btn--text'
            "
            >Lucky Pick</v-btn
          >
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from "./../mixins/helpers";

export default {
  mixins: [helpers],
  props: ["game", "slug"],

  data() {
    return {
      drawDay: this.game.draw.day,
      drawDate: this.game.draw.date,
      drawLabel: this.game.draw.label,
      jackpotAmount: this.game.jackpot_amount,
    };
  },
  computed: {
    isQuickPick() {
      return this.$route.params.pick === "quick-pick";
    },

    hasJackpot() {
      if (this.jackpotAmount === null) {
        return false;
      }
      return this.jackpotAmount;
    },
  },
  methods: {
    gameHelp() {
      window.open(this.game.help_url, "_system");
    },
    goBack() {
      this.$router.push({ name: "play" });
    },
    refresh() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="sass" scoped>
.pcso-game-header
  background-image: linear-gradient(21deg, #4165b7, #602d9a)
  background-size: cover
  .draw-list-head
    background: #0e193a0f
  .price-bg
    background-color: #24388247
    border-radius: 10px
    position: relative
  .pcso-game-logo
    background: #fff
    border-radius: 10px

  .v-btn-toggle > .switch-btn.v-btn.v-btn
    border-radius: 20px !important
  .switch-btn-group
    border: 3px solid #f3f3f3

  @keyframes blink
    0%
      opacity: 0
    49%
      opacity: 0
    50%
      opacity: 1

  .blink
    animation: blink 1s infinite
</style>