<template>
  <v-container class="pa-4">
    <v-row>
      <v-col
        class="py-0 px-2"
        cols="6"
        sm="6"
        md="4"
        v-for="(game, index) in games"
        :key="index"
      >
        <v-card
          v-show="game.status === 'open'"
          @click.stop="openGame(game)"
          flat
          class="mb-4 d-flex"
          style="min-height: 122px"
        >
          <v-row
            class="d-flex align-center justify-center"
            style="min-height: 95px;"
          >
            <v-col
              cols
              class="d-flex align-center justify-center px-0 flex-column"
            >
              <v-avatar class tile size="40">
                <img :src="'/imgs/' + game.slug + '.png'" />
              </v-avatar>
              <div v-if="game.jackpot_amount" class>
                <p class="caption mb-0 grey--text text-center">Jackpot Price</p>
                <h2
                  class="body-2 font-weight-bold orange--text text--darken-2"
                  style="margin-top:-5px;"
                >
                  &#8369; {{ formatMoney(game.jackpot_amount) }}
                </h2>
              </div>
              <v-chip
                v-if="!hasDraw(game)"
                x-small
                color="indigo lighten-4 mt-1 blue-grey--text text-darken-1"
              >
                No Draws
              </v-chip>
              <v-chip
                color="indigo lighten-1 white--text mt-2"
                x-small
                v-if="hasDraw(game)"
                >{{ getFirstDrawLabel(game) }} Draw</v-chip
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import helpers from "./../mixins/helpers";
export default {
  mixins: [helpers],
  props: ["games"],

  data() {
    return {
      jackpotAmount: this.games.jackpot_amount,
    };
  },

  methods: {
    getFirstDrawLabel(game) {
      if (this.hasDraw(game)) {
        return _.head(game.draws).label;
      }
      return null;
    },
    openGame(game) {
      if (this.hasDraw(game)) {
        this.$router.push({ path: "/game/" + game.slug + "/manual-pick" });
      } else {
        return "";
      }
    },

    hasDraw(game) {
      return game.draws.length;
    },
  },
};
</script>

<style lang="sass">
.pcso-tile
  overflow: hidden !important
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.05)

.pcso-game
  min-width: 100px
  text-align: center
  img
    max-width: 60px
    .sched
      color: red
      font-size: .9em
    .prize
      font-weight: 600
      font-size: 1.15em
</style>
