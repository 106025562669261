<template>
  <v-container>
    <v-row>
      <v-col class="mt-4">
        <v-select
          v-if="gameSelection"
          v-model="selectedGame"
          :items="gameSelection"
          label="Select Game"
          item-text="name"
          item-value="id"
          hide-details
          @change="selectGame"
          :loading="loading"
          :disabled="loading"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="hasList">
      <div class="ticket-date" v-for="(resultList, index) in listsByDate" :key="index">
        <v-subheader class="text-uppercase font-weight-medium pa-0">
          {{
          index
          }}
        </v-subheader>
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
            sm="6"
            md="4"
            v-for="(result, index) in resultList"
            :key="index"
          >
            <tickets
              class="tickets-comb"
              :title="result.draw.client.name + ' ' + result.draw.label"
              :status="result.win"
              :valid="valid(result)"
              :numberOfDraws="draws(result)"
              :drawHasResult="
                result.draw_has_result &&
                  result.advance_bet_with_no_result_count == 0 &&
                  !result.win
              "
              :advanceWin="result.advance_wins"
              :ticketref="result.ticket"
              :submitAt="'Submitted: ' + result.created_at"
              :betamount="'&#8369;' + formatMoney(result.amount)"
              :numbers="result.combination.split(',')"
              :slug="result.draw.client.slug"
              :roll="result.roll"
              :isRambol="result.is_rambol"
              :advance_bets="result.advance_bets"
              @set-current-ticket="setCurrentTicket"
            />
          </v-col>
        </v-row>
      </div>

      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card class="mb-8 ticket-dialog">
          <v-toolbar dark flat color="orange accent-3">
            <v-toolbar-title>Advance Bets</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="pt-4 pb-10" style="background: #e5e3e9;">
            <tickets
              class="tickets-comb"
              v-for="(advanceResult, index) in currentAdvanceBet"
              :key="index"
              :title="currentTicket.title"
              :status="advanceResult.win"
              :ticketref="advanceResult.ticket"
              :drawHasResult="
                advanceResult.draw_has_result && !advanceResult.win
              "
              :submitAt="'Submitted :' + advanceResult.created_at"
              :valid="'VALID for ' + advanceResult.schedule_date"
              :betamount="'&#8369;' + formatMoney(advanceResult.amount)"
              :numbers="advanceResult.combination.split(',')"
              :slug="currentTicket.slug"
              :roll="advanceResult.roll"
              :is_rambol="advanceResult.is_rambol"
            />
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="loading && lists && lists.length" class="text-center text-lose">
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="heading" class="mb-2"></v-skeleton-loader>
          <v-skeleton-loader
            type="image"
            height="130"
            class="mb-2"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="image"
            height="130"
            class="mb-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!loading && lists && (lists.length < 1)">
      <small>{{ loading ? "Loading..." : "You have no tickets yet" }}</small>
    </div>
    <v-row>
      <v-col>
        <div v-if="hasMore" class="text-center mb-8">
          <v-btn depressed small :loading="loading" @click.stop.prevent="loadMore()">Show More</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import list from "./../mixins/list";
import helpers from "./../mixins/helpers";
import _ from "lodash";
import Tickets from "./../components/Tickets";

export default {
  mixins: [list, helpers],
  components: {
    Tickets,
  },

  data: () => ({
    dataUrl: "/lotto/tickets",
    dialog: false,
    currentAdvanceBet: [],
    currentTicket: {},
    selectedGame: 1,
  }),

  methods: {
    selectGame() {
      if (this.selectedGame) {
        this.selectedGame === 1
          ? (this.params = "")
          : (this.params = "&client_id=" + this.selectedGame);
        this.getList();
      }
    },
    draws(result) {
      let numberOfDraws = result.advance_bets.length;
      if (numberOfDraws > 0) {
        return "Tap to view " + numberOfDraws + " tickets ";
      }
      return "";
    },
    valid(result) {
      return "Valid until: " + result.valid_until;
    },
    setCurrentTicket(ticketData) {
      this.currentTicket = ticketData;
      this.currentAdvanceBet = this.currentTicket.advanceBets;

      if (this.currentAdvanceBet.length > 0) {
        this.dialog = true;
      }
    },
  },

  computed: {
    listsByDate() {
      return _.groupBy(this.lists, "created_at_date");
    },
    gameSelection() {
      return this.$store.getters.gameSelection;
    },
  },
};
</script>

<style lang="sass" scoped></style>
