<template>
  <v-container class="pa-3 ma-0" fluid>
    <update-name />
    <update-password />
    <clear-history />
  </v-container>
</template>

<script>
export default {
  components: {
    UpdateName: () => import('./../sections/UpdateName'),
    UpdatePassword: () => import('./../sections/UpdatePassword'),
    ClearHistory: () => import('./../sections/ClearHistory'),
  }
};
</script>

<style lang="sass" scoped>
.sheet-form
  background: rgb(37, 37, 37)
</style>