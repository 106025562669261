<template>
    <div 
      class="pcso-page-header elevation-2 pb-7"
      :style="{ backgroundImage: backgroundStyle }"
    >
      <v-btn icon class="mt-3 ml-3" @click.stop="$router.push({ name: backButton })">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <h1 class="pcso-game-title text-center mt-2">{{ title }}</h1>
      <div class="mb-6"><slot></slot></div>
    </div>
</template>

<script>
export default {
    props: {
      title: {
        type: String,
        default: 'Results'
      },
      backButton: {
        type: String,
        default: 'play'
      }, 
      bg: {
        type: String,
        default: 'results-bg.png'
      }     
    },
    computed: {
      backgroundStyle () {
        return 'url("/imgs/' + this.bg + '")'
      }
    }
}
</script>

<style lang="sass" scoped>
.pcso-page-header
    background-size: cover
    .pcso-game-title
      margin-top: -.5em
      font-size: 1.6em
      color: rgba(255,255,255,.9)
      text-shadow: 2px 2px 2px rgba(0,0,0,0.4)

</style>